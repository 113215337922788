<template>
  <div>
    <form @submit.prevent="agregarItem">
      <div class="row py-2 mb-3 border border-secondary">
        <div class="col-sm-2">
          <div class="form-group">
            <label>Parentesco:</label>
            <b-form-select v-model="parentescoSelected" :options="getParentescos"></b-form-select>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-group">
            <label>Nombre y Apellido:</label>
            <input v-model="nombreCompleto"
             type="text" class="form-control" autocomplete="off">
          </div>
        </div>
        <div class="col-sm-2">
          <div class="row">
            <div class="col-sm-5 pl-0">
              <div class="form-group">
                <label>Celular:</label>
                <multiselect v-model="paisSelected" :options="getPaises" :allow-empty="false"
                  :show-labels="false" :searchable="false"
                  :close-on-select="true" label="descripcion" track-by="descripcion"
                  @input="setCodigoPais">
                  <template slot="singleLabel" slot-scope="props">
                    <img class="option__image"
                      :src="props.option.img"
                      :alt="props.option.descripcion"
                      width="26px">
                  </template>
                  <template slot="option" slot-scope="props">
                    <img class="option__image"
                      :src="props.option.img"
                      :alt="props.option.descripcion"
                      width="26px">
                  </template>
                </multiselect>
              </div>
            </div>
            <div class="col-sm-7 px-0 py-1">
              <div class="form-group">
                <label></label>
                <input type="text"
                  class="form-control"
                  :placeholder="'+'+codigoPais"
                  v-model="celular"
                  autocomplete="off"
                  @keydown="validarCaracter"
                  @blur="reValidarCelular">
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-group">
            <label>Celula de identidad:</label>
            <input type="text" v-model="ci"
              class="form-control" autocomplete="off">
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-group">
            <label>Email:</label>
            <input type="email" v-model="email"
              class="form-control" autocomplete="off">
          </div>
        </div>
        <div class="col-sm-1 pt-3">
          <button type="submit" class="btn btn-warning">Agregar</button>
        </div>
      </div>
    </form>
    <div class="separator-breadcrumb border-top"></div>
    <b-table class="table table-hover" :fields="fields" :items="getlistaContactos"></b-table>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { mapMutations, mapGetters } from 'vuex';
import { util } from '../../../plugins/util';

export default {
  name: 'Contacto',
  components: { Multiselect },
  async created() {
    /* eslint-disable prefer-destructuring */
    this.paisSelected = this.getPaises[0];
    this.codigoPais = this.getPaises[0].codigo_telefono;
  },
  data() {
    return {
      nombreCompleto: '',
      codigoPais: '',
      celular: '',
      paisSelected: null,
      parentescoSelected: 1,
      email: '',
      fields: [
        { key: 'numero', label: 'N°', thStyle: { width: '10%' } },
        {
          key: 'parentesco', label: 'Parentesco', class: 'text-center', thStyle: { width: '18%' },
        },
        {
          key: 'nombres', label: 'Nombre y Apellido', class: 'text-center', thStyle: { width: '20%' },
        },
        {
          key: 'telefono_completo', label: 'Celular', class: 'text-center', thStyle: { width: '20%' },
        },
        {
          key: 'ci', label: 'CI', class: 'text-center', thStyle: { width: '10%' },
        },
        {
          key: 'mail', label: 'Email', class: 'text-center', thStyle: { width: '17%' },
        },
      ],
      ci: null,
    };
  },
  beforeDestroy() {
    this.setlistaContactos([]);
  },
  methods: {
    setCodigoPais() {
      this.codigoPais = this.paisSelected.codigo_telefono;
    },
    validarCaracter(event) {
      const validKeys = new RegExp('^[0-9]*$');
      const controlKeys = ['Delete', 'Backspace', 'ArrowLeft', 'ArrowRight', 'KeyX', 'KeyC', 'KeyV', 'Home', 'End', 'Tab'];
      if (controlKeys.includes(event.code)) {
        switch (event.code) {
          case 'KeyX':
            if (!event.ctrlKey) {
              event.preventDefault();
            }
            break;
          case 'KeyC':
            if (!event.ctrlKey) {
              event.preventDefault();
            }
            break;
          case 'KeyV':
            if (!event.ctrlKey) {
              event.preventDefault();
            }
            break;
          default:
            break;
        }
        return;
      }
      if (!validKeys.test(event.key)) {
        event.preventDefault();
      }
    },
    reValidarCelular() {
      if (this.celular === '') {
        return;
      }
      const validKeys = new RegExp('^[0-9]*$');
      if (!validKeys.test(this.celular)) {
        this.celular = '';
      }
    },
    resetearComboParentesco() {
      this.parentescoSelected = 1;
    },
    resetearInputNombres() {
      this.nombreCompleto = '';
    },
    resetearCelular() {
      this.paisSelected = this.getPaises[0];
      this.codigoPais = this.getPaises[0].codigo_telefono;
      this.celular = '';
    },
    resetearInputEmail() {
      this.email = '';
    },
    resetearCi() {
      this.ci = null;
    },
    agregarItem() {
      this.nombreCompleto = this.nombreCompleto.trim();
      if (this.nombreCompleto === '') {
        util.showNotify('Escribe el Nombre y Apellido', 'warn');
        return;
      }
      this.celular = this.celular.trim();
      if (this.celular === '') {
        util.showNotify('Escribe el numero de Celular', 'warn');
        return;
      }
      this.email = this.email.trim();
      const numero = this.getlistaContactos.length + 1;
      const item = {
        numero,
        id: 0,
        parentesco: this.getParentescos[this.parentescoSelected - 1].text,
        nombres: this.nombreCompleto,
        telefono: this.celular,
        telefono_completo: `+${this.codigoPais} ${this.celular}`,
        mail: this.email,
        pais_id: this.paisSelected.id,
        parentesco_id: this.parentescoSelected,
        estado: 'true',
        ci: this.ci,
      };
      this.addItemlistaContactos(item);
      this.resetearComboParentesco();
      this.resetearInputNombres();
      this.resetearCelular();
      this.resetearInputEmail();
      this.resetearCi();
    },
    ...mapMutations('contacto', ['setlistaContactos', 'addItemlistaContactos']),
  },
  computed: {
    ...mapGetters('contacto', ['getPaises', 'getlistaContactos']),
    ...mapGetters('parentescos', ['getParentescos']),
  },
};
</script>

<style scoped>
</style>
