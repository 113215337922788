<template>
  <div class="card-body">
    <div class="row mb-2">
      <div class="col-sm-6" style="display: inline;">
        <b><font size=3>{{title? title: 'S/N'}}</font></b>
      </div>
      <div class="col-sm-6" v-show="reconsulta">
        <div class="form-group row">
          <label for="fecha_reconsulta" class="col-sm-4 col-form-label text-right">
            Fecha Reconsulta:
          </label>
          <div class="col-sm-4" style="margin: auto;">
            <date-picker
              class="col-sm-12"
              v-model="fecha"
              lang="es"
              format="DD/MM/YYYY"
              type="date"
              value-type="date"
            ></date-picker>
          </div>
          <div class="col-sm-4" style="margin: auto;">
            <multiselect2
                v-model="almacenSelected"
                track-by="nombre"
                label="nombre"
                placeholder="Seleccione"
                :options="almacenes"
                :searchable="true"
                >
              </multiselect2>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-sm-12" style="background: #f4b375;">
        <div class="form-group row">
          <div class="col-sm-10 pt-2">
            <button
              v-if="isActiveFarmacia"
              class="btn btn-sm text-white mr-2"
              :class="getClassButtonFarmacia"
              :disabled="consulta === null"
              :id="'popover-target-1'"
              type="button"
              @click="openModalFarmacia(categoriaFarmacia)"
            >
              {{getNameFarmacia}}
            </button>
            <button
              v-if="isActiveLaboratorio"
              class="btn btn-sm text-white mr-2"
              :id="'popover-target-2'"
              type="button"
              :class="getClassButtonLaboratorio"
              :disabled="consulta === null"
              @click="openModalServicios(getNameLaboratorio, categoriaLaboratorio)"
            >
              {{getNameLaboratorio}}
            </button>
            <button
              v-if="isActiveEcografia"
              class="btn btn-sm text-white mr-2"
              :id="'popover-target-3'"
              type="button"
              :class="getClassButtonEcografia"
              :disabled="consulta === null"
              @click="openModalServicios(getNameEcografia, categoriaEcografia)"
            >
              {{getNameEcografia}}
            </button>
            <button
              v-if="isActiveRayosX"
              class="btn btn-sm text-white mr-2"
              :id="'popover-target-4'"
              type="button"
              :class="getClassButtonRayosX"
              :disabled="consulta === null"
              @click="openModalServicios(getNameRayosX, categoriaRayosX)"
            >
              {{getNameRayosX}}
            </button>
            <button
              v-if="isActiveTomografia"
              class="btn btn-sm text-white mr-2"
              :id="'popover-target-5'"
              type="button"
              :class="getClassButtonTomografia"
              :disabled="consulta === null"
              @click="openModalServicios(getNameTomografia, categoriaTomografia)"
            >
              {{getNameTomografia}}
            </button>
            <button
              v-if="isActiveMamografia"
              class="btn btn-sm text-white mr-2"
              :id="'popover-target-5'"
              type="button"
              :class="getClassButtonMamografia"
              :disabled="consulta === null"
              @click="openModalServicios(getNameMamografia, categoriaMamografia)"
            >
              {{getNameMamografia}}
            </button>
            <button
              v-if="isActiveArcoC"
              class="btn btn-sm text-white mr-2"
              :class="getClassButtonArcoC"
              :disabled="consulta === null"
              @click="openModalServicios(getNameArcoEnC, categoriaArcoC)"
            >
              {{getNameArcoEnC}}
            </button>
            <button
              v-if="isActiveFisioterapia"
              class="btn btn-sm text-white mr-2"
              :class="getClassButtonFisioterapia"
              :disabled="consulta === null"
              @click="openModalFisioterapia(getNameFisioterapia, categoriaFisioterapia)"
            >
              {{getNameFisioterapia}}
            </button>
            <button
              v-if="isActiveTratamiento"
              class="btn btn-sm text-white mr-2"
              :class="getClassButtonTratamiento"
              :disabled="consulta === null"
              @click="openModalFisioterapia(getNameTratamiento, categoriaTratamiento)"
            >
              {{getNameTratamiento}}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-2">
      <SectionTableAndAddPharmacies
        v-if="nombreButtonActivo === getNameFarmacia"
        :listaTabla="recipeList"
      />
      <div
        v-if="categoriaServicioComplId === categoriaFisioterapia
        || categoriaServicioComplId === categoriaTratamiento"
        class="col-sm-12 px-0"
      >
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th width="5%" class="th-custom-color text-center">N°</th>
              <th width="37%" class="th-custom-color text-center">Servicio</th>
              <th width="20%" class="th-custom-color text-center">N° de Session</th>
              <th width="35%" class="th-custom-color text-center">Descripción</th>
              <th width="3%" class="th-custom-color text-center"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(servicio, index) in listTempServiciosComplementarios" :key="index">
              <td class="text-center"> {{ index + 1 }}</td>
              <td class="text-center"> {{ servicio.categoria }} </td>
              <td class="text-center"> {{ servicio.session }}</td>
              <td class="text-center"> {{ servicio.nombre }} </td>
              <td class="text-center">
                <i
                  class="nav-icon i-Close-Window font-weight-bold text-danger"
                  style="cursor: pointer"
                  @click="eliminarServicio(servicio)"
                ></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        v-if="
          categoriaServicioComplId === categoriaLaboratorio
          || categoriaServicioComplId === categoriaEcografia
          || categoriaServicioComplId === categoriaRayosX
          || categoriaServicioComplId === categoriaTomografia
          || categoriaServicioComplId === categoriaMamografia
        || categoriaServicioComplId === categoriaArcoC
        "
        class="col-sm-12 px-0"
      >
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th width="5%" class="th-custom-color text-center">N°</th>
              <th width="23%" class="th-custom-color text-center">Categoria</th>
              <th width="10%" class="th-custom-color text-center">Cant.</th>
              <th width="25%" class="th-custom-color text-center">Servicios</th>
              <th width="45%" class="th-custom-color text-center">Descripcion</th>
              <th width="3%" class="th-custom-color text-center"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(servicio, index) in listTempServiciosComplementarios" :key="index">
              <td class="text-center"> {{ index + 1 }} </td>
              <td class="text-center"> {{ servicio.categoria }} </td>
              <td class="text-center">
                <vue-numeric class="form-control"
                  name="cantidad"
                  v-model="servicio.cantidad"
                  separator=","
                  placeholder=""/>
              </td>
              <td class="text-center"> {{ servicio.nombre }} </td>
              <td class="text-center">
                <input type="text"
                  v-model="servicio.descripcion"
                  class="form-control"
                  >
              </td>
              <td class="text-center">
                <i
                  class="nav-icon i-Close-Window font-weight-bold text-danger"
                  style="cursor: pointer"
                  @click="eliminarServicio(servicio)"
                ></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <TheModalServicios
    :titleModal="descripcionTitulo"
    @addServiciosComplemetarios="addServiciosComplemetarios"
    />
    <TheModalFisioterapia
    :titleModal="descripcionTitulo"
    @addServiciosComplemetarios="addServiciosComplemetarios"/>
    <TheModalFarmacia
      :entry-data="getItemFarmacia"
      @modal-saved="getRecipeList($event)"
    />
</div>
</template>

<script>
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';
import { mapState, mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import Multiselect2 from '@/components/util/vue-multiselect2';
import { CATEGORIA_SERVICIO_COMPL } from '@/utils/globalConventions';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';
import VueNumeric from 'vue-numeric';
import TheModalServicios from './TheModalServicios.vue';
import TheModalFisioterapia from './TheModalFisioterapia.vue';
import TheModalFarmacia from './TheModalFarmacia.vue';
import SectionTableAndAddPharmacies from './SectionTableAndAddPharmacies.vue';

export default {
  name: 'TheServiciosComplementarios',
  props: {
    reconsulta: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    itemList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      descripcionTitulo: null,
      nombreButtonActivo: '',
      recipeList: [],
      categoriaServicioComplId: null,
      listTempServiciosComplementarios: [],
    };
  },
  created() {
    this.getRecipeList(true);
    this.detectAndSetComponentActive();
  },
  methods: {
    detectAndSetComponentActive() {
      if (this.isActiveFarmacia) {
        this.nombreButtonActivo = this.getNameFarmacia;
        return;
      }
      if (this.isActiveLaboratorio) {
        this.nombreButtonActivo = 'Laboratorio';
        return;
      }
      if (this.isActiveEcografia) {
        this.nombreButtonActivo = 'Ecografia';
        return;
      }
      if (this.isActiveRayosX) {
        this.nombreButtonActivo = 'Rayos-X';
        return;
      }
      if (this.isActiveTomografia) {
        this.nombreButtonActivo = 'Tomografia';
        return;
      }
      if (this.isActiveMamografia) {
        this.nombreButtonActivo = 'Mamografia';
        return;
      }
      if (this.isActiveArcoC) {
        this.nombreButtonActivo = 'Arco_c';
        return;
      }
      if (this.isActiveFisioterapia) {
        this.nombreButtonActivo = 'Fisioterapia';
        return;
      }
      if (this.isActiveTratamiento) {
        this.nombreButtonActivo = 'Tratamiento';
        return;
      }
      this.nombreButtonActivo = '';
    },
    async openModalServicios(titleModal, catServicioCompId) {
      this.descripcionTitulo = titleModal;
      this.nombreButtonActivo = titleModal;
      this.categoriaServicioComplId = catServicioCompId;
      await this.resetDataTheModaServios();
      const DTO = {
        catServicioCompId,
        seguro: null,
      };
      await this.getDataServicioComplementario(DTO);
      this.$bvModal.show('theModalProductoServicios');
    },
    async openModalFisioterapia(titleModal, categoriaId) {
      this.descripcionTitulo = titleModal;
      this.nombreButtonActivo = titleModal;
      this.categoriaServicioComplId = categoriaId;
      await this.resetDataTheModaServios();
      const DTO = {
        catServicioCompId: categoriaId,
        seguro: null,
      };
      await this.getDataServicioComplementario(DTO);
      this.$bvModal.show('TheModalFisioterapia');
    },
    async openModalFarmacia(categoriaId) {
      this.categoriaServicioComplId = categoriaId;
      if (this.consulta === null) {
        return;
      }
      this.temporaPageComboProducto = 0;
      this.resetDataModalFarmacia();
      await this.getListProducto(null);
      this.$bvModal.show('theModalFarmacia');
      this.nombreButtonActivo = this.getNameFarmacia;
    },
    async getRecipeList(result) {
      if (result === false) {
        return;
      }
      try {
        const params = {
          filter_key: '',
          page: 1,
          state: true,
          per_page: 1000,
          consulta_id: this.consulta.id,
        };
        const response = await axios.get('/clinic/consultation/patient/receta', {
          params,
        });
        this.recipeList = response.data.data.recetas.data;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    addServiciosComplemetarios(dtos) {
      const SERVICIOS_COMPLEMENTARIOS = dtos;
      SERVICIOS_COMPLEMENTARIOS.forEach((element, index) => {
        const OBJ = {
          id: 0,
          servicio_complementario_id: SERVICIOS_COMPLEMENTARIOS[index].servicio_complementario_id,
          estado: SERVICIOS_COMPLEMENTARIOS[index].estado,
          cantidad: SERVICIOS_COMPLEMENTARIOS[index].cantidad,
          session: SERVICIOS_COMPLEMENTARIOS[index].session,
          categoria_serv_compl_id: this.categoriaServicioComplId,
          numero: SERVICIOS_COMPLEMENTARIOS[index].numero,
          categoria: SERVICIOS_COMPLEMENTARIOS[index].categoria,
          nombre: SERVICIOS_COMPLEMENTARIOS[index].nombre,
          numero_interno: SERVICIOS_COMPLEMENTARIOS[index].numero_interno,
          descripcion_servicio: SERVICIOS_COMPLEMENTARIOS[index].descripcion_servicio,
          key: SERVICIOS_COMPLEMENTARIOS[index].key,
        };
        this.lisServiciosComplementarios.push(OBJ);
      });
      this.filterListaTempServicioComplementario();
    },
    ...mapActions('ConsultaExternaEdit', [
      'getListProducto',
      'resetDataModalFarmacia',
    ]),
    ...mapActions('theModalServicios', [
      'resetDataTheModaServios',
      'getDataServicioComplementario',
    ]),
    filterListaTempServicioComplementario() {
      switch (this.categoriaServicioComplId) {
        case this.categoriaLaboratorio:
          this.listTempServiciosComplementarios = this.lisServiciosComplementarios.filter((obj) => (
            obj.categoria_serv_compl_id === this.categoriaLaboratorio && obj.estado === true
          ));
          break;
        case this.categoriaEcografia:
          this.listTempServiciosComplementarios = this.lisServiciosComplementarios.filter((obj) => (
            obj.categoria_serv_compl_id === this.categoriaEcografia && obj.estado === true
          ));
          break;
        case this.categoriaRayosX:
          this.listTempServiciosComplementarios = this.lisServiciosComplementarios.filter((obj) => (
            obj.categoria_serv_compl_id === this.categoriaRayosX && obj.estado === true
          ));
          break;
        case this.categoriaTomografia:
          this.listTempServiciosComplementarios = this.lisServiciosComplementarios.filter((obj) => (
            obj.categoria_serv_compl_id === this.categoriaTomografia && obj.estado === true
          ));
          break;
        case this.categoriaMamografia:
          this.listTempServiciosComplementarios = this.lisServiciosComplementarios.filter((obj) => (
            obj.categoria_serv_compl_id === this.categoriaMamografia && obj.estado === true
          ));
          break;
        case this.categoriaArcoC:
          this.listTempServiciosComplementarios = this.lisServiciosComplementarios.filter((obj) => (
            obj.categoria_serv_compl_id === this.categoriaArcoC && obj.estado === true
          ));
          break;
        case this.categoriaFisioterapia:
          this.listTempServiciosComplementarios = this.lisServiciosComplementarios.filter((obj) => (
            obj.categoria_serv_compl_id === this.categoriaFisioterapia && obj.estado === true
          ));
          break;
        case this.categoriaTratamiento:
          this.listTempServiciosComplementarios = this.lisServiciosComplementarios.filter((obj) => (
            obj.categoria_serv_compl_id === this.categoriaTratamiento && obj.estado === true
          ));
          break;

        default:
          break;
      }
    },
    eliminarServicio(item) {
      const ID = item.id;
      if (ID > 0) {
        const INDEX = this.lisServiciosComplementarios.findIndex((el) => (
          el.id === item.id));
        this.lisServiciosComplementarios[INDEX].estado = false;
      } else {
        const INDEX = this.lisServiciosComplementarios.findIndex((el) => (
          el.key === item.key));
        this.lisServiciosComplementarios.splice(INDEX, 1);
      }
      this.filterListaTempServicioComplementario();
    },
  },
  computed: {
    ...mapState('ConsultaExternaEdit', [
      'almacenSelected',
      'almacenes',
      'monedaPrincipal',
      'temporaPageComboProducto',
      'productoSelected',
      'consulta',
    ]),
    ...mapFields('ConsultaExternaEdit', [
      'fecha',
      'almacenSelected',
      'almacenes',
      'monedaPrincipal',
      'temporaPageComboProducto',
      'productoSelected',
      'lisServiciosComplementarios',
    ]),
    getNameFarmacia() {
      if (this.getItemFarmacia != null) {
        return this.getItemFarmacia.nombre;
      }
      return 'Farmacia';
    },
    getNameLaboratorio() {
      if (this.getItemLaboratorio != null) {
        return this.getItemLaboratorio.nombre;
      }
      return 'Laboratorio';
    },
    getNameEcografia() {
      if (this.getItemEcografia != null) {
        return this.getItemEcografia.nombre;
      }
      return 'Ecografia';
    },
    getNameRayosX() {
      if (this.getItemRayosX != null) {
        return this.getItemRayosX.nombre;
      }
      return 'Rayos X';
    },
    getNameTomografia() {
      if (this.getItemTomografia != null) {
        return this.getItemTomografia.nombre;
      }
      return 'Tomografia';
    },
    getNameMamografia() {
      if (this.getItemMamografia != null) {
        return this.getItemMamografia.nombre;
      }
      return 'Mamografia';
    },
    getNameArcoEnC() {
      if (this.getItemArcoC != null) {
        return this.getItemArcoC.nombre;
      }
      return 'Arco en C';
    },
    getNameFisioterapia() {
      if (this.getItemFisioterapia != null) {
        return this.getItemFisioterapia.nombre;
      }
      return 'Fisioterapia';
    },
    getNameTratamiento() {
      if (this.getItemTratamiento != null) {
        return this.getItemTratamiento.nombre;
      }
      return 'Tratamiento';
    },
    getItemFarmacia() {
      if (this.itemList.length > 0) {
        const item = this.itemList.find((el) => (
          el.servicio_complementario_categoria_id === CATEGORIA_SERVICIO_COMPL.FARMACIA))
          ?? null;
        return item;
      }
      return null;
    },
    getItemLaboratorio() {
      if (this.itemList.length > 0) {
        const item = this.itemList.find((el) => (
          el.servicio_complementario_categoria_id === CATEGORIA_SERVICIO_COMPL.LABORATORIO))
          ?? null;
        return item;
      }
      return null;
    },
    getItemEcografia() {
      if (this.itemList.length > 0) {
        const item = this.itemList.find((el) => (
          el.servicio_complementario_categoria_id === CATEGORIA_SERVICIO_COMPL.ECOGRAFIA))
          ?? null;
        return item;
      }
      return null;
    },
    getItemRayosX() {
      if (this.itemList.length > 0) {
        const item = this.itemList.find((el) => (
          el.servicio_complementario_categoria_id === CATEGORIA_SERVICIO_COMPL.RAYOS_X))
          ?? null;
        return item;
      }
      return null;
    },
    getItemTomografia() {
      if (this.itemList.length > 0) {
        const item = this.itemList.find((el) => (
          el.servicio_complementario_categoria_id === CATEGORIA_SERVICIO_COMPL.TOMOGRAFIA))
          ?? null;
        return item;
      }
      return null;
    },
    getItemMamografia() {
      if (this.itemList.length > 0) {
        const item = this.itemList.find((el) => (
          el.servicio_complementario_categoria_id === CATEGORIA_SERVICIO_COMPL.MAMOGRAFIA))
          ?? null;
        return item;
      }
      return null;
    },
    getItemArcoC() {
      if (this.itemList.length > 0) {
        const item = this.itemList.find((el) => (
          el.servicio_complementario_categoria_id === CATEGORIA_SERVICIO_COMPL.ARCO_C))
          ?? null;
        return item;
      }
      return null;
    },
    getItemFisioterapia() {
      if (this.itemList.length > 0) {
        const item = this.itemList.find((el) => (
          el.servicio_complementario_categoria_id === CATEGORIA_SERVICIO_COMPL.FISIOTERAPIA))
          ?? null;
        return item;
      }
      return null;
    },
    getItemTratamiento() {
      if (this.itemList.length > 0) {
        const item = this.itemList.find((el) => (
          el.servicio_complementario_categoria_id === CATEGORIA_SERVICIO_COMPL.TRATAMIENTO))
          ?? null;
        return item;
      }
      return null;
    },
    isActiveFarmacia() {
      if (this.getItemFarmacia != null) {
        return this.getItemFarmacia.estado;
      }
      return false;
    },
    isActiveLaboratorio() {
      if (this.getItemLaboratorio != null) {
        return this.getItemLaboratorio.estado;
      }
      return false;
    },
    isActiveEcografia() {
      if (this.getItemEcografia != null) {
        return this.getItemEcografia.estado;
      }
      return false;
    },
    isActiveRayosX() {
      if (this.getItemRayosX != null) {
        return this.getItemRayosX.estado;
      }
      return false;
    },
    isActiveTomografia() {
      if (this.getItemTomografia != null) {
        return this.getItemTomografia.estado;
      }
      return false;
    },
    isActiveMamografia() {
      if (this.getItemMamografia != null) {
        return this.getItemMamografia.estado;
      }
      return false;
    },
    isActiveArcoC() {
      if (this.getItemArcoC != null) {
        return this.getItemArcoC.estado;
      }
      return false;
    },
    isActiveFisioterapia() {
      if (this.getItemFisioterapia != null) {
        return this.getItemFisioterapia.estado;
      }
      return false;
    },
    isActiveTratamiento() {
      if (this.getItemTratamiento != null) {
        return this.getItemTratamiento.estado;
      }
      return false;
    },
    getClassButtonFarmacia() {
      let classToApply = 'btn-light';
      /* if (this.consulta === null) {
        return classToApply;
      } */
      if (this.nombreButtonActivo === this.getNameFarmacia) {
        classToApply = 'btn-success';
      } else {
        classToApply = 'btn-secondary';
      }
      return classToApply;
    },
    getClassButtonLaboratorio() {
      let classToApply = 'btn-secondary';
      if (this.nombreButtonActivo === this.getNameLaboratorio) {
        classToApply = 'btn-success';
      }
      return classToApply;
    },
    getClassButtonEcografia() {
      let classToApply = 'btn-secondary';
      if (this.nombreButtonActivo === this.getNameEcografia) {
        classToApply = 'btn-success';
      }
      return classToApply;
    },
    getClassButtonRayosX() {
      let classToApply = 'btn-secondary';
      if (this.nombreButtonActivo === this.getNameRayosX) {
        classToApply = 'btn-success';
      }
      return classToApply;
    },
    getClassButtonTomografia() {
      let classToApply = 'btn-secondary';
      if (this.nombreButtonActivo === this.getNameTomografia) {
        classToApply = 'btn-success';
      }
      return classToApply;
    },
    getClassButtonMamografia() {
      let classToApply = 'btn-secondary';
      if (this.nombreButtonActivo === this.getNameMamografia) {
        classToApply = 'btn-success';
      }
      return classToApply;
    },
    getClassButtonArcoC() {
      let classToApply = 'btn-secondary';
      if (this.nombreButtonActivo === this.getNameArcoEnC) {
        classToApply = 'btn-success';
      }
      return classToApply;
    },
    getClassButtonFisioterapia() {
      let classToApply = 'btn-secondary';
      if (this.nombreButtonActivo === this.getNameFisioterapia) {
        classToApply = 'btn-success';
      }
      return classToApply;
    },
    getClassButtonTratamiento() {
      let classToApply = 'btn-secondary';
      if (this.nombreButtonActivo === this.getNameTratamiento) {
        classToApply = 'btn-success';
      }
      return classToApply;
    },
    categoriaEcografia() {
      return CATEGORIA_SERVICIO_COMPL.ECOGRAFIA;
    },
    categoriaLaboratorio() {
      return CATEGORIA_SERVICIO_COMPL.LABORATORIO;
    },
    categoriaRayosX() {
      return CATEGORIA_SERVICIO_COMPL.RAYOS_X;
    },
    categoriaTomografia() {
      return CATEGORIA_SERVICIO_COMPL.TOMOGRAFIA;
    },
    categoriaMamografia() {
      return CATEGORIA_SERVICIO_COMPL.MAMOGRAFIA;
    },
    categoriaArcoC() {
      return CATEGORIA_SERVICIO_COMPL.ARCO_C;
    },
    categoriaFisioterapia() {
      return CATEGORIA_SERVICIO_COMPL.FISIOTERAPIA;
    },
    categoriaTratamiento() {
      return CATEGORIA_SERVICIO_COMPL.TRATAMIENTO;
    },
    categoriaFarmacia() {
      return CATEGORIA_SERVICIO_COMPL.FARMACIA;
    },
  },
  watch: {
    categoriaServicioComplId(new1) {
      if (new1) {
        this.filterListaTempServicioComplementario();
      }
    },
  },
  components: {
    DatePicker,
    TheModalServicios,
    TheModalFisioterapia,
    TheModalFarmacia,
    SectionTableAndAddPharmacies,
    Multiselect2,
    VueNumeric,
  },
};
</script>
