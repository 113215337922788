import modalPieza from './modalPieza';
import modalHojaAdmision from './modalHojaAdmision';
import salaCama from './salaCama';
import theDiagnostic from './theDiagnostico';
import theModalInterConsulta from './theModalInterConsulta';

export default {
  modalPieza,
  modalHojaAdmision,
  salaCama,
  theDiagnostic,
  theModalInterConsulta,
};
