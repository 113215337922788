import { getField, updateField } from 'vuex-map-fields';
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default {
  namespaced: true,
  state: {
    familiar: [], // Aquí se almacenará la respuesta de la API
    contactos: [],
    isLoading: false,
    queryTempContacto: null,
    temporalPageComboContacto: 1,

    // nuevoContactos
    parentescos: [],
    newContacto: false,
    paises: [],
    familiarSelect: null,
    contactosNewPacientes: [],
    isAgregar: false,
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    SET_FAMILIARES(state, familiares) {
      state.familiar = familiares; // Mutación para actualizar el estado
    },
    setContactos: (state, data) => { state.contactos = data; },
    setIsLoading: (state, data) => { state.isLoading = data; },
    setQueryTempContacto: (state, data) => { state.queryTempContacto = data; },
    setTemporalPageComboContacto: (state, data) => { state.temporalPageComboContacto = data; },
    setParentescos: (state, data) => { state.parentescos = data; },
    setNewContacto: (state, data) => { state.newContacto = data; },
    setPaises: (state, data) => { state.paises = data; },
    setFamiliarSelected: (state, data) => { state.familiarSelect = data; },
    setContactoNewPacientes: (state, data) => { state.contactosNewPacientes = data; },
    setIsAgregar: (state, data) => { state.isAgregar = data; },
  },
  actions: {
    async getFamiliares({ commit }, pacienteId) {
      try {
        const response = await axios.get(`/clinic/patient/familiar_contacto/${pacienteId}`);
        commit('SET_FAMILIARES', response.data.data.familiar_paciente); // Guardar familiares en el estado
        commit('setContactos', response.data.data.contacto_paciente);
      } catch (error) {
        console.error('Error fetching familiares:', error);
      }
    },
    async getAllContactos({ commit, state }, query) {
      try {
        commit('setIsLoading', true);
        if (query !== null) {
          commit('setQueryTempContacto', query);
        } else {
          commit('setTemporalPageComboContacto', state.temporalPageComboContacto + 1);
        }
        const REQUEST = {
          filter_key: state.queryTempContacto,
          page: 1,
          per_page: 20 * state.temporalPageComboContacto,
        };
        const response = await axios.get('/clinic/patient/contacts', {
          params: REQUEST,
        });
        // console.log('response', response.data.data.contactos_pacientes);
        commit('setContactos', response.data.data.contactos_pacientes);
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
        commit('setIsLoading', false);
      } finally {
        commit('setIsLoading', false);
      }
    },
    async createContacto({ commit }) {
      try {
        const response = await axios.get('/clinic/patient/contact/create');
        const { parentescos } = response.data.data;
        commit('setParentescos', parentescos);
        const { paises } = response.data.data;
        commit('setPaises', paises);
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async storeContactoNew({ commit }, data) {
      try {
        const contactos = data.pacienteContactos;
        // const INDEX_FINAL = contactos.length - 1;
        const DATA = {
          paciente_id: data.paciente_id,
          paciente_contacto: contactos,
        };
        const response = await axios.post('/clinic/patient/contact/store', DATA);
        const FAMILIAR_SELECTED = {
          id: response.data.data.contacto_paciente_id,
          nombres: contactos[0].nombres,
          ci: contactos[0].ci,
          pais_id: contactos[0].pais_id,
          telefono: contactos[0].telefono,
          mail: contactos[0].mail,
        };
        commit('setFamiliarSelected', FAMILIAR_SELECTED);
      } catch (error) {
        commit('setIsAgregar', false);
        util.showNotify(error.response.data.message, 'error');
      }
    },
    resetModalHojaAdminision({ commit }) {
      commit('SET_FAMILIARES', []);
      commit('setContactos', []);
      commit('setIsLoading', false);
      commit('setQueryTempContacto', null);
      commit('setTemporalPageComboContacto', 1);
      commit('setParentescos', []);
      commit('setNewContacto', false);
      commit('setPaises', []);
      commit('setFamiliarSelected', null);
      commit('setContactoNewPacientes', []);
      commit('setIsAgregar', false);
    },
  },
};
