import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default {
  async getDatoInicial({ commit }, dto) {
    const ID = dto.id;
    const params = {
      paciente_id: dto.paciente_id,
    };
    try {
      const RESPONSE = await axios.get(`/clinic/tratamientos/enfermeria/${ID}/edit`, { params });
      commit('setPaciente', RESPONSE.data.data.datos_paciente);
      commit('setIndicacionMedicas', RESPONSE.data.data.indicaciones_medicas);
      commit('setNumeroTratamiento', RESPONSE.data.data.numero_internacion);
      commit('setTipoMonitoreo', RESPONSE.data.data.tipos_monitoreo);
      /*  commit('setHasPrintSignosVitales', RESPONSE.data.data.has_first_print_signos_vitales);
      commit('setHasPrintCambioPosicion', RESPONSE.data.data.has_first_print_cambio_posicion);
      commit('setHasPrintBalanceHidrico', RESPONSE.data.data.has_first_print_balance_hidrico);
     */
      commit('setHasPrintSignosVitales', true);
      commit('setHasPrintCambioPosicion', true);
      commit('setHasPrintBalanceHidrico', true);
      return true;
    } catch (error) {
      const FALLO_VALIDACION = 422;
      if (error.response.status !== FALLO_VALIDACION) {
        util.showNotify(error.response.data.message, 'error');
      } else {
        Object.entries(error.response.data.data).forEach(([, mensajes]) => {
          mensajes.forEach((texto) => util.showNotify(texto, 'error'));
        });
      }
      return false;
    }
  },
  resetDataListarAtencionEnfermeria({ commit }) {
    commit('setEstado', true);
    commit('setPorPagina', 10);
    commit('setBusqueda', null);
    commit('setListaTablas', []);
    commit('setPaginaActual', 1);
    commit('setTotalFilas', 0);
    commit('setNumeroTratamiento', null);
    commit('setTipoMonitoreo', []);
  },
};
