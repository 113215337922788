<template>
    <div v-if="isLoading">
      <div class="text-center text-danger screen-center">
        <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
        <strong>Cargando...</strong>
      </div>
    </div>
    <div v-else>
      <div>
        <div class="row mr-0">
          <div class="col-sm-3">
            <div class="breadcrumb">
              <h1>
                Internacion
                <span class="h4 pl-2" v-if="edit">
                  N°: {{numero_internacion}}
                </span>
              </h1>
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-12">
            <div class="card text-left">
              <div class="card-header">
                <div class="row">
                  <div class="col-sm-5">
                      <button class="btn btn-white border border-light mr-2" @click="back">
                        Regresar
                      </button>
                      <button type="button" class="btn btn-dark mr-2" @click="storeHistorialClinico">
                          Guardar
                        </button>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="row pt-3">
                      <div class="col d-flex" v-if="edit">
                        <strong>Fecha: </strong>
                        <div class="px-3">
                          {{fecha_ingreso}} - Iniciado {{hora_ingreso}}
                        </div>
                      </div>
                    </div>
                    <div class="row pb-3">
                      <div class="col-12">
                        <strong>Paciente:</strong>
                        {{dataPaciente.full_name || dataPaciente.nombre_comercial}}
                      </div>
                    </div>
                    <div class="row mx-2">
                        <div class="form-group">
                            <b><font size=3>Motivo de Internacion:</font></b>
                            <textarea name="" v-model="localHistorialClinico.motivo_consulta"
                              cols="90" rows="4" class="form-control"
                            >
                            </textarea>
                          </div>
                    </div>
                    <div class="row mx-2">
                        <div class="form-group pt-2">
                            <b><font size=3>Enfermedad Actual:</font></b>
                            <textarea name=""
                             v-model="localHistorialClinico.enfermedad_actual"
                              cols="90" rows="7" class="form-control"
                            >
                            </textarea>
                          </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="row mx-2">
                        <div class="form-group pt-2">
                            <b><font size=3>Examen Fisico:</font></b>
                            <textarea name=""
                             v-model="localHistorialClinico.examen_fisico"
                              cols="90" rows="7" class="form-control"
                            >
                            </textarea>
                          </div>
                    </div>
                    <div class="row mx-2">
                        <div class="form-group pt-2">
                            <b><font size=3>Tratante:</font></b>
                            <textarea name=""
                             v-model="localHistorialClinico.tratante"
                              cols="90" rows="7" class="form-control"
                            >
                            </textarea>
                          </div>
                    </div>
                  </div>
                </div>
                <div class="container-fluid btnOrange">
                    <div class="row">
                        <h4 class="mt-3 mx-3"><strong>ANTECEDENTES GINECO OBSTETRICOS</strong></h4>
                    </div>
                    <div class="row mx-3 mt-2 mb-5">
                      <div class="col-sm-2 d-flex align-items-center custom-col">
                            <label for="fum" class="mr-2"><b>FUM:</b> </label>
                            <date-picker
                            class="col-sm-12"
                            v-model="localHistorialClinico.antecedente_gineco_obstetrico.fum"
                            lang="es"
                            format="DD/MM/YYYY"
                            type="date"
                            value-type="date"
                          ></date-picker>
                        </div>

                        <div class="col-sm-3 d-flex align-items-center custom-col">
                            <label for="fpp" class="mr-2"><b>FPP:</b> </label>
                            <date-picker
                            class="col-sm-12"
                            v-model="localHistorialClinico.antecedente_gineco_obstetrico.fpp"
                            lang="es"
                            format="DD/MM/YYYY"
                            type="date"
                            value-type="date"
                          ></date-picker>
                        </div>
                        <div class="col-sm-2 d-flex align-items-center custom-col">
    <label for="g" class="mr-2"><b>G: </b></label>
    <textarea
        id="g"
        v-model="localHistorialClinico.antecedente_gineco_obstetrico.gestaciones"
        class="form-control"
        rows="2"
        style="resize: none;"
        @input="validateNumberInput('gestaciones')"
    ></textarea>
</div>
<div class="col-sm-2 d-flex align-items-center custom-col">
    <label for="p" class="mr-2"><b>P: </b></label>
    <textarea
        id="p"
        v-model="localHistorialClinico.antecedente_gineco_obstetrico.partos"
        class="form-control"
        rows="2"
        style="resize: none;"
        @input="validateNumberInput('partos')"
    ></textarea>
</div>
<div class="col-sm-2 d-flex align-items-center custom-col">
    <label for="ab" class="mr-2"><b>AB:</b></label>
    <textarea
        id="ab"
        v-model="localHistorialClinico.antecedente_gineco_obstetrico.abortos"
        class="form-control"
        rows="2"
        style="resize: none;"
        @input="validateNumberInput('abortos')"
    ></textarea>
</div>
<div class="col-sm-2 d-flex align-items-center custom-col">
    <label for="c" class="mr-2"><b>C:</b></label>
    <textarea
        id="c"
        v-model="localHistorialClinico.antecedente_gineco_obstetrico.cesareas"
        class="form-control"
        rows="2"
        style="resize: none;"
        @input="validateNumberInput('cesareas')"
    ></textarea>
</div>

                        <div class="col-sm-2 d-flex align-items-center custom-col">
                            <label for="pap" class="mr-2"><b>PAP:</b></label>
                            <textarea id="pap" v-model="localHistorialClinico.antecedente_gineco_obstetrico.pap" class="form-control" rows="2" style="resize: none;"></textarea>
                        </div>
                      </div>    <div class="container-fluid mt-3">
                        <div class="row mx-5">
                          <div class="col-md-5 mr-5 mb-4">
                            <div class="form-group">
                              <label for="craneo"><b>Craneo</b></label>
                              <input type="text" v-model="localHistorialClinico.antecedente_gineco_obstetrico.craneo" class="form-control" id="craneo">
                            </div>
                            <div class="form-group">
                              <label for="cara"><b>Cara</b></label>
                              <input type="text" v-model="localHistorialClinico.antecedente_gineco_obstetrico.cara" class="form-control" id="cara">
                            </div>
                            <div class="form-group">
                              <label for="cuello"><b>Cuello</b></label>
                              <input type="text" v-model="localHistorialClinico.antecedente_gineco_obstetrico.cuello" class="form-control" id="cuello">
                            </div>
                            <div class="form-group">
                              <label for="torax"><b>Torax</b></label>
                              <input type="text" v-model="localHistorialClinico.antecedente_gineco_obstetrico.torax" class="form-control" id="torax">
                            </div>
                            <div class="form-group">
                              <label for="corazon"><b>Corazon</b></label>
                              <input type="text" v-model="localHistorialClinico.antecedente_gineco_obstetrico.corazon" class="form-control" id="corazon">
                            </div>
                            <div class="form-group">
                              <label for="pulmones"><b>Pulmones</b></label>
                              <input type="text" v-model="localHistorialClinico.antecedente_gineco_obstetrico.pulmones" class="form-control" id="pulmones">
                            </div>
                          </div>
                          <div class="col-md-5 ml-5 mb-4">
                            <div class="form-group">
                              <label for="abdomen"><b>Abdomen</b></label>
                              <input type="text" v-model="localHistorialClinico.antecedente_gineco_obstetrico.abdomen" class="form-control" id="abdomen">
                            </div>
                            <div class="form-group">
                              <label for="genitales"><b>Genitales</b></label>
                              <input type="text" v-model="localHistorialClinico.antecedente_gineco_obstetrico.genitales" class="form-control" id="genitales">
                            </div>
                            <div class="form-group">
                              <label for="extremindades"><b>Extremidades</b></label>
                              <input type="text" v-model="localHistorialClinico.antecedente_gineco_obstetrico.extremidades" class="form-control" id="extremindades">
                            </div>
                            <div class="form-group">
                              <label for="sistema"><b>Sistema Nervioso Central</b></label>
                              <input type="text" v-model="localHistorialClinico.antecedente_gineco_obstetrico.sistema_nervioso_central" class="form-control" id="sistema">
                            </div>
                            <div class="form-group">
                              <label for="indicaciones"><b>Indicaciones</b></label>
                              <input type="text" v-model="localHistorialClinico.antecedente_gineco_obstetrico.indicaciones" class="form-control" id="indicaciones">
                            </div>
                          </div>
                        </div>
                      </div>
                </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
<script>
import { util } from '@/plugins/util';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';
import moment from 'moment';

export default {
  name: 'historia-clinica',
  props: {
    dataPaciente: {
      type: Object,
      required: false,
      default: null,
    },
    edit: {
      type: Boolean,
      required: false,
      default: false,
    },
    activeHC: {
      type: Boolean,
      required: false,
      default: false,
    },
    historialClinico: {
      type: Object,
    },
    numero_internacion: {
      type: String,
      default: null,
    },
    fecha_ingreso: {
      type: String,
      default: null,
    },
    hora_ingreso: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      localActiveHC: this.activeHC, // Estado local basado en el prop activeHC
      localHistorialClinico: this.historialClinico || this.initializeHistorialClinico(),
    };
  },

  components: {
    DatePicker,
  },
  watch: {
    activeHC(newValue) {
      this.localActiveHC = newValue;
    },
    historialClinico(newValue) {
      this.localHistorialClinico = newValue || {};
    },
  },
  created() {
    if (this.edit) {
      if (this.localHistorialClinico?.antecedente_gineco_obstetrico?.fum) {
        this.localHistorialClinico.antecedente_gineco_obstetrico.fum = this.normalizeDate(
          this.localHistorialClinico.antecedente_gineco_obstetrico.fum,
        );
      }

      if (this.localHistorialClinico?.antecedente_gineco_obstetrico?.fpp) {
        this.localHistorialClinico.antecedente_gineco_obstetrico.fpp = this.normalizeDate(
          this.localHistorialClinico.antecedente_gineco_obstetrico.fpp,
        );
      }
    }
  },
  methods: {
    initializeHistorialClinico() {
      if (this.edit) {
        return {
          id: 0,
          enfermedad_actual: '',
          examen_fisico: '',
          tratante: '',
          antecedente_gineco_obstetrico: {
            id: 0,
            fum: null,
            fpp: null,
            gestaciones: '',
            partos: '',
            abortos: '',
            cesareas: '',
            pap: '',
            craneo: '',
            cara: '',
            cuello: '',
            torax: '',
            corazon: '',
            pulmones: '',
            abdomen: '',
            genitales: '',
            extremidades: '',
            sistema_nervioso_central: '',
            indicaciones: '',
          },
        };
      }
      return {
        motivo_consulta: '',
        enfermedad_actual: '',
        examen_fisico: '',
        tratante: '',
        antecedente_gineco_obstetrico: {
          fum: null,
          fpp: null,
          gestaciones: '',
          partos: '',
          abortos: '',
          cesareas: '',
          pap: '',
          craneo: '',
          cara: '',
          cuello: '',
          torax: '',
          corazon: '',
          pulmones: '',
          abdomen: '',
          genitales: '',
          extremidades: '',
          sistema_nervioso_central: '',
          indicaciones: '',
        },
      };
    },
    normalizeDate(dateString) {
      return moment.utc(dateString).local().toDate();
    },
    storeHistorialClinico() {
      if (this.localHistorialClinico.antecedente_gineco_obstetrico.fum != null) {
        this.localHistorialClinico.antecedente_gineco_obstetrico.fum = this.getFechaFormat(
          this.localHistorialClinico.antecedente_gineco_obstetrico.fum,
          'YYYY-MM-DD HH:mm:ss',
        );
      }
      if (this.localHistorialClinico.antecedente_gineco_obstetrico.fpp != null) {
        this.localHistorialClinico.antecedente_gineco_obstetrico.fpp = this.getFechaFormat(
          this.localHistorialClinico.antecedente_gineco_obstetrico.fpp,
          'YYYY-MM-DD HH:mm:ss',
        );
      }
      this.localActiveHC = false;
      this.$emit('historial-clinico-enviado', this.verificarHistorialClinico(this.localHistorialClinico), this.localActiveHC, this.dataPaciente);
      util.showNotify('Guardado', 'success');
      this.isLoading = false;
    },
    getFechaFormat(dateString, formato) {
      return moment(dateString).format(formato);
    },
    back() {
      this.localHistorialClinico = this.verificarHistorialClinico(this.localHistorialClinico);
      this.localActiveHC = false;
      this.$emit('historial-clinico-enviado', this.verificarHistorialClinico(this.localHistorialClinico), this.localActiveHC, this.dataPaciente);
    },
    validateNumberInput(field) {
      const value = this.localHistorialClinico.antecedente_gineco_obstetrico[field];
      this.localHistorialClinico.antecedente_gineco_obstetrico[field] = value.replace(/\D/g, '');
    },
    verificarHistorialClinico(obj) {
      const estaVacio = Object.values(obj).every((value) => {
        if (typeof value === 'object' && value !== null) {
          return Object.values(value).every((innerValue) => {
            if (innerValue === 'Invalid date' || (innerValue instanceof Date)) {
              return true;
            }
            return innerValue === '' || innerValue === null || innerValue === 0;
          });
        }

        if (value === 'Invalid date' || (value instanceof Date)) {
          return true;
        }

        return value === '' || value === null || value === 0;
      });

      if (estaVacio) {
        return [];
      }

      return obj;
    },
    limpiarHistorialClinico() {
      this.localHistorialClinico = [{
        motivo_consulta: '',
        enfermedad_actual: '',
        examen_fisico: '',
        tratante: '',
        antecedente_gineco_obstetrico: {
          fum: null,
          fpp: null,
          gestaciones: '',
          partos: '',
          abortos: '',
          cesareas: '',
          pap: '',
          craneo: '',
          cara: '',
          cuello: '',
          torax: '',
          corazon: '',
          pulmones: '',
          abdomen: '',
          genitales: '',
          extremidades: '',
          sistema_nervioso_central: '',
          indicaciones: '',
        },
      }];
    },
  },
};
</script>

  <style scoped>
  .custom-col {
    flex: 0 0 12.5%;
    max-width: 15.5%;
  }
.btnOrange {
    background-color: #fdd6b1;
  }
  </style>
