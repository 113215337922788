<template>
  <div v-if="mostrar" class="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Notificación</h5>
          <button type="button" class="close" @click="cerrarModal">
            <span>&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <slot>
            <!-- Contenido predeterminado -->
            <p>Llego a su limite de seguro</p>
          </slot>
        </div>
        <div class="modal-footer justify-content-center">
          <button type="button" class="btn btn-danger" @click="cerrarModal">
            Aceptar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mostrar: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['cerrar'],
  methods: {
    cerrarModal() {
      this.$emit('cerrar');
    },
  },
};
</script>

<style>
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1050;
}

.modal-dialog {
  max-width: 500px;
}

.modal-content {
  background: white;
  border-radius: 5px;
  overflow: hidden;
}
</style>
