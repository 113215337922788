<template>
  <div>
    <div class="row mx-0">
      <div class="col-sm-7" style="padding: 5px;">
        <table class="table table-bordered mb-0 table-custom">
  <thead class="thead-white" style="border: none;">
    <tr>
      <th class="text-center" style="width: 20%;">Consulta Externa</th>
      <th class="text-center" style="width: 25%;"></th>
      <th class="text-center" style="width: 4%;">Mostrar</th>
      <th class="text-center" style="width: 6%;">Obligatorio</th>
      <th class="text-center" style="width: 20%;">Cantidad de Caracteres</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="(item) in parametrosFiltrados" :key="item.id" >
        <!-- Nombre del parámetro -->
        <td class="text-right">{{ item.valor }}</td>
        <!-- Input de texto -->
        <td>
          <b-input-group>
            <input
              type="text"
              class="form-control"
              v-model="item.valor"
              :placeholder="item.valor"
              :disabled="!item.visible"
              @keyup.enter="updateMotivoConsulta(item)"
            >
            <b-input-group-append>
              <button
                class="btn btn-light px-2"
                @click="updateMotivoConsulta(item)"
                :disabled="!item.visible"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  fill="currentColor"
                  class="bi bi-floppy"
                  viewBox="0 0 16 16"
                >
                  <path d="M11 2H9v3h2z"/>
                  <path d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16
                    2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0
                    0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5
                    0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0
                    0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5
                    7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5m3 4a.5.5 0 0 0
                    .5.5h7a.5.5 0 0 0 .5-.5V1H4zM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5
                    0 0 0-.5.5z"/>
                </svg>
              </button>
            </b-input-group-append>
          </b-input-group>
        </td>

        <!-- Checkbox Visible -->
        <td class="text-center">
          <input
            type="checkbox"
            v-model="item.visible"
            @click="toggleVisible(item)"
          >
        </td>

        <!-- Checkbox Obligatorio -->
        <td class="text-center">
          <input
            type="checkbox"
            v-model="item.obligatorio"
            @click="toggleObligatorio(item)"
          >
        </td>
        <!-- Input para Cantidad de Caracteres -->
        <td>
          <input
            type="number"
            class="form-control"
            v-model="item.cantidad_caracteres"
            :disabled="true"
            @change="updateCaracter(item)"
          >
        </td>
      </tr>
    </tbody>
</table>
        <div class="form-group row bg-row mb-1">
          <label
            for="servicios_complementarios"
            class="col-sm-3 pr-0 d-flex justify-content-end align-items-center"
          >
            Servicios Complementarios:
          </label>
          <div class="col-sm-4 py-1">
            <b-input-group>
              <input
                type="text"
                class="form-control"
                v-model="parametros.servicioComplementario"
                placeholder="Servicios Complementarios"
                @keyup.enter="updateServicioComplementarioParametros"
                :disabled="isDisabled"
              >
              <b-input-group-append>
                <button
                  class="btn btn-light px-2"
                  @click="updateServicioComplementarioParametros"
                  :disabled="isDisabled"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    fill="currentColor"
                    class="bi bi-floppy"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11 2H9v3h2z"/>
                    <path d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16
                      2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0
                      0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5
                      0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0
                      0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5
                      7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5m3 4a.5.5 0 0 0
                      .5.5h7a.5.5 0 0 0 .5-.5V1H4zM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5
                      0 0 0-.5.5z"
                    />
                  </svg>
                </button>
              </b-input-group-append>
            </b-input-group>
          </div>
          <div class="col-sm-5 bg-white"></div>
        </div>
      </div>
      <div class="col-sm-5">
        <div class="row mb-3">
          <div class="col-sm-10 pl-4 pr-5">
            <p>
              El objetivo es que en Consulta Externa, tenga un mínimo
              de registro o información que tiene que poner el Médico.
            </p>
            <p>Si es Menos podra Guardar pero no calculará su comisión.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-10 offset-sm-2"
            style="border: 1px solid; margin: auto; padding: 4%;">
            <div class="row">
              <div class="col-sm-12 px-0">
                <strong class="mr-2">Activar Cotización</strong>
                <small>(Es para que Genere de forma Automática una Cotización)</small>
              </div>
            </div>
            <div class="row bg-row py-2 mb-3">
              <div class="col-sm-1 offset-sm-1 d-flex text-justify-center">
                <input type="checkbox" v-model="textoActivacion.activarRecetaMedica" disabled=true>
              </div>
              <div class="col-sm-4 pl-0">
                <strong>Receta Médica</strong>
              </div>
              <div class="col-sm-1 offset-sm-1 d-flex text-justify-center">
                <input type="checkbox" v-model="textoActivacion.activarServicio" disabled=true>
              </div>
              <div class="col-sm-4 pl-0">
                <strong>Servicios</strong>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 px-0">
                <input type="checkbox" class="mr-1"
                v-model="textoActivacion.activarTiempoCierre" disabled=true>
                <strong class="mr-1">Activar</strong>
                <small>
                  (Es para que el sistema Cierre Automáticamente como esta esa Atención)
                </small>
              </div>
            </div>
            <div class="row bg-row py-2 mb-3">
              <div class="col-sm-4 text-right pr-0">
                <label class="col-form-label"><b>Tiempo de Cierre:</b></label>
              </div>
              <div class="col-sm-4 pr-0">
                <input
                  type="time"
                  class="form-control"
                  v-model="textoActivacion.tiempoCierre"
                  disabled=true
                >
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 px-0 text-center">
                <input type="checkbox" class="mr-1"
                v-model="textoActivacion.activarTratante">
                <strong class="mr-1">Activar Filtro "Tratante"</strong>
              </div>
            </div>
            <div class="row bg-row py-2 mb-3">
              <div class="col-sm-12 pr-0">
                <span style="color: orange;">
                  Ventas: Es cuando selecciona servicios, muestre en "Tratante" solo
                  a los medicos que estan relacionados con ese servicios y a los demas no mostrara.
                </span>
              </div>
            </div>
            <div class="row float-right">
              <button class="btn bg-custom-green mr-2"
              @click="storeActivacionTexto">
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-7">
        <div class="row mb-1">
          <div class="col-sm-12 pl-1 pr-5">
            <p>
              <b>Funcion de Internacion en Servicio Complementario</b>
            </p>
          </div>
        </div>
        <div class="row bg-row mb-1">
          <div class="col-sm-12 offset-sm-2" style="border: 1px solid; margin: auto; padding: 2%;">
            <div class="mx-3 bg-col">
              <p class="pl-4">Según la clínica quiera usar</p>
              <div class="row pl-3 pb-2">
                <div class="col-sm-4 px-0 text-center">
                  <input type="checkbox" class="mr-1" v-model="checkRecetar" @click="habilitarEMyReceta">
                  <strong class="mr-1">Recetar</strong>
                </div>
                <div class="col-sm-4 px-0 text-center">
                  <input type="checkbox" class="mr-1" v-model="checkInsumo">
                  <strong class="mr-1">Insumo</strong>
                </div>
                <div class="col-sm-4 px-0 text-center">
                  <input type="checkbox" class="mr-1" v-model="checkVentas">
                  <strong class="mr-1">Ventas</strong>
                </div>
              </div>
            </div>

            <div class="mx-3 mt-2 bg-col">
              <p class="pl-4">Funciones para que ocultar o mostrar</p>
              <div class="row pl-5 pb-2">
                <div class="col-sm-4 px-2 text-center">
                  <input type="checkbox" class="mr-1" v-model="checkEvolucionMedica"
                    @click="habilitarEMyReceta">
                  <strong class="mr-1">Evolucion Medica</strong>
                </div>
                <div class="col-sm-4 px-0 text-center">
                  <input type="checkbox" class="mr-1"
                    v-model="checkServicioExt">
                  <strong class="mr-1">Servicios(Ext).</strong>
                </div>
                <div class="col-sm-4 px-0 text-center">
                  <input type="checkbox" class="mr-1"
                    v-model="checkServicioInt">
                  <strong class="mr-1">Servicios(Int).</strong>
                </div>
              </div>
            </div>
            <div class="row mt-3 justify-content-center">
              <button class="btn bg-custom-green mr-2"
                @click="storeServicioInternacion">
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default {
  name: 'TheTextos',
  data() {
    return {
      parametros: [],
      isDisabled: true,
      textoActivacion: {
        id: 0,
        activarTiempoCierre: false,
        activarTratante: false,
        activarRecetaMedica: false,
        activarServicio: false,
        tiempoCierre: null,
      },
      checkEvolucionMedica: false,
      checkServicioInt: false,
      checkServicioExt: false,
      checkRecetar: false,
      checkInsumo: false,
      checkVentas: false,
      parametro_consulta_textos: [],
      parametrosUnificados: [],
    };
  },
  created() {
    this.getParametrosTextos();
  },
  methods: {
    habilitarEMyReceta() {
      if (!this.checkRecetar && !this.checkEvolucionMedica) {
        this.checkRecetar = true;
        this.checkEvolucionMedica = true;
      } else {
        this.checkRecetar = false;
        this.checkEvolucionMedica = false;
      }
    },
    async getParametrosTextos() {
      this.isDisabled = true;
      try {
        const response = await axios.get('/clinic/servicio_complementario/nombre_parametro_servicio_consulta/create');
        this.parametros = response.data.data.nombre_parametro_servicio_consulta;
        this.parametro_consulta_textos = response.data.data.parametro_consulta_textos;

        // Combinar los arrays
        this.parametrosUnificados = this.parametros.map((servicio) => {
          const texto = this.parametro_consulta_textos.find((item) => item.nombre === servicio.parametro);
          return {
            id: servicio.id, // ID del objeto `nombre_parametro_servicio_consulta`
            parametro_consulta_id: texto ? texto.id : null, // ID del objeto `parametro_consulta_textos`
            parametro: servicio.parametro,
            valor: servicio.valor,
            obligatorio: servicio.obligatorio,
            visible: servicio.visible,
            cantidad_caracteres: 0,
          };
        });
        const activacionTexto = response.data.data.texto_activacion;
        if (activacionTexto) {
          this.textoActivacion.id = activacionTexto.id;
          this.textoActivacion.activarTiempoCierre = activacionTexto.activar_tiempo_cierre;
          this.textoActivacion.activarTratante = activacionTexto.activar_filtro_tratante;
          this.textoActivacion.activarRecetaMedica = activacionTexto.receta_medica;
          this.textoActivacion.activarServicio = activacionTexto.servicio;
          this.textoActivacion.tiempoCierre = activacionTexto.tiempo_cierre;
        }
        this.checkEvolucionMedica = response.data.data.has_active_evolucion_medica;
        this.checkServicioExt = response.data.data.has_active_servicio_ext;
        this.checkServicioInt = response.data.data.has_active_servicio_int;
        this.checkRecetar = response.data.data.has_active_receta;
        this.checkInsumo = response.data.data.has_active_insumo;
        this.checkVentas = response.data.data.has_active_venta;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      } finally {
        this.isDisabled = false;
      }
    },
    updateParametro(item) {
      console.log(item);
    },
    toggleVisible(item) {
      // eslint-disable-next-line no-param-reassign
      item.visible = !item.visible;
      if (item.visible) {
        // eslint-disable-next-line no-param-reassign
        item.obligatorio = true;
      } else {
        // eslint-disable-next-line no-param-reassign
        item.obligatorio = false;
      }
      this.updateMotivoConsulta(item);
    },

    toggleObligatorio(item) {
      // eslint-disable-next-line no-param-reassign
      item.obligatorio = !item.obligatorio;
      this.updateMotivoConsulta(item);
    },
    updateCaracter(item) {
      // eslint-disable-next-line no-param-reassign, no-self-assign
      item.cantidad_caracteres = Number(item.cantidad_caracteres);
      this.updateMotivoConsulta(item);
    },
    async updateMotivoConsulta(item) {
      try {
        const RESPONSE = await axios.post('/clinic/servicio_complementario/nombre_parametro_servicio_consulta', item);
        const parametros = RESPONSE.data.data.nombre_parametro_servicio_consulta;
        util.showNotify(RESPONSE.data.message, 'success');
        this.parametros.id = parametros.id;
        this.parametros.motivoConsulta = parametros.motivo_consulta;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async updateEnfermedadActual() {
      try {
        const REQUEST = {
          id: this.parametros.id,
          enfermedad_actual: this.parametros.enfermedadActual,
        };
        const RESPONSE = await axios.post('/clinic/servicio_complementario/nombre_parametro_servicio_consulta', REQUEST);
        const parametros = RESPONSE.data.data.nombre_parametro_servicio_consulta;
        util.showNotify(RESPONSE.data.message, 'success');
        this.parametros.id = parametros.id;
        this.parametros.enfermedadActual = parametros.enfermedad_actual;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async updateExamenFisico() {
      try {
        const REQUEST = {
          id: this.parametros.id,
          examen_fisico: this.parametros.examenFisico,
        };
        const RESPONSE = await axios.post('/clinic/servicio_complementario/nombre_parametro_servicio_consulta', REQUEST);
        const parametros = RESPONSE.data.data.nombre_parametro_servicio_consulta;
        util.showNotify(RESPONSE.data.message, 'success');
        this.parametros.id = parametros.id;
        this.parametros.examenFisico = parametros.examen_fisico;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async updateTratamiento() {
      try {
        const REQUEST = {
          id: this.parametros.id,
          tratamiento: this.parametros.tratamiento,
        };
        const RESPONSE = await axios.post('/clinic/servicio_complementario/nombre_parametro_servicio_consulta', REQUEST);
        const parametros = RESPONSE.data.data.nombre_parametro_servicio_consulta;
        util.showNotify(RESPONSE.data.message, 'success');
        this.parametros.id = parametros.id;
        this.parametros.tratamiento = parametros.tratamiento;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async updateServicioComplementarioParametros() {
      try {
        const REQUEST = {
          id: this.parametros.id,
          servicio_complementario: this.parametros.servicioComplementario,
        };
        const RESPONSE = await axios.post('/clinic/servicio_complementario/nombre_parametro_servicio_consulta', REQUEST);
        const parametros = RESPONSE.data.data.nombre_parametro_servicio_consulta;
        util.showNotify(RESPONSE.data.message, 'success');
        this.parametros.id = parametros.id;
        this.parametros.servicioComplementario = parametros.servicio_complementario;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async storeActivacionTexto() {
      try {
        const REQUEST = {
          id: this.textoActivacion.id,
          receta_medica: this.textoActivacion.activarRecetaMedica,
          servicio: this.textoActivacion.activarServicio,
          acticar_cierre: this.textoActivacion.activarTiempoCierre,
          tiempo_cierre: this.textoActivacion.tiempoCierre,
          activar_tratante: this.textoActivacion.activarTratante,
        };
        const RESPONSE = await axios.post('/clinic/servicio_complementario/store_parametro_activacion_texto', REQUEST);
        util.showNotify(RESPONSE.data.message, 'success');
        const activacionTexto = RESPONSE.data.data;
        if (activacionTexto) {
          this.textoActivacion.id = activacionTexto.id;
          this.textoActivacion.activarTiempoCierre = activacionTexto.activar_tiempo_cierre;
          this.textoActivacion.activarTratante = activacionTexto.activar_filtro_tratante;
          this.textoActivacion.activarRecetaMedica = activacionTexto.receta_medica;
          this.textoActivacion.activarServicio = activacionTexto.servicio;
          this.textoActivacion.tiempoCierre = activacionTexto.tiempo_cierre;
        }
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    activarTiempoCierre() {
      if (this.textoActivacion.activarTiempoCierre === false) {
        this.textoActivacion.tiempoCierre = null;
      }
    },
    async storeServicioInternacion() {
      try {
        const REQUEST = {
          receta: this.checkRecetar,
          insumo: this.checkInsumo,
          venta: this.checkVentas,
          evolucion_medica: this.checkEvolucionMedica,
          servicio_ext: this.checkServicioExt,
          servicio_int: this.checkServicioInt,
        };
        const RESPONSE = await axios.post('/clinic/servicio_complementario/store_parametro_servicio_internacion', REQUEST);
        util.showNotify(RESPONSE.data.message, 'success');
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
  },
  computed: {
    parametrosFiltrados() {
      return this.parametrosUnificados.filter((item) => item.parametro !== 'servicio_complementario');
    },
  },
};
</script>

<style scoped>
.bg-row {
  background-color: #eee;
}
.bg-col {
  background-color: #ffffff;
}
.table-custom {
  border-collapse: separate;
  border-spacing: 0 10px; /* Espaciado entre filas */
}

.table-custom td {
  background-color: rgb(240, 240, 240);
  border-top: 1px solid #000000; /* Borde superior de la fila */
  border-bottom: 1px solid #000000;
}

.table-custom thead tr {
  border-bottom: 2px solid #000000; /* Borde inferior más grueso para el encabezado */
}

.table-custom .input-group-append button {
  border: 1px solid #000000; /* Bordes de botón similares */
}
.table-custom tbody tr td:first-child {
  border-left: 1px solid #000000;
}

.table-custom tbody tr td:last-child {
  border-right: 1px solid #000000; /* Borde derecho en la primera columna */
  /* Borde izquierdo en la última columna */
}

.table-custom thead tr {
  border: none; /* Borde inferior más grueso para el encabezado */
}
</style>
