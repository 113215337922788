export default () => ({
  estado: true,
  porPagina: 10,
  busqueda: null,
  listaTablas: [],
  paginaActual: 1,
  totalFilas: 0,
  paciente: null,
  indicacionsMedicas: [],
  numeroTratamiento: null,
  tipoMonitoreo: [],
  hasPrintSignosVitales: false,
  hasPrintCambioPosicion: false,
  hasPrintBalanceHidrico: false,
});
