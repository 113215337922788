<template>
  <div>
    <b-modal id="modal-reserva"
    size="custom-lg-max"
    title="Reservar"
    body-class="pt-0"
    hide-footer>
      <template #modal-header="{ close }">
        <div class="col-sm-12">
          <div class="form-group row">
            <div class="col-sm-3">
              <div class="col-sm-12 text-left">
                <h1 style="font-size: 20px;">
                  <strong> Reservar </strong>
                </h1>
              </div>
            </div>
            <div class="col-sm-4 text-right">
                <button class="mr-2"
                  :class="{'btn': true, 'btn-orange': filtroSeleccionado === 'clinica',
                    'btn-outline-dark': filtroSeleccionado !== 'clinica'}"
                  @click="seleccionarClinica">
                  Clinica
                </button>
                <button :class="{'btn': true, 'btn-orange': filtroSeleccionado === 'medico',
                  'btn-outline-dark': filtroSeleccionado !== 'medico'}"
                  @click="seleccionarMedico">
                  Medico
                </button>
              </div>
            <div class="col-sm-4 text-right">
              <input type="text" class="form-control"
              v-model="busqueda"
              autocomplete="off"
              @keyup="filtrarBusqueda"
              placeholder="Buscar Paciente(Codigo, #, CI y/o Nombre)">
            </div>
            <div class="col-sm-1 text-right">
              <span class="font-weight-bold align-middle" @click="close()"
              style="cursor: pointer">X</span>
            </div>
          </div>
        </div>
      </template>
      <div class="row">
        <div class="col-sm-12 px-0">
          <b-table responsive class="table"
          :fields="computedFields"
          :items="listaReserva"
          :per-page="por_pagina">
          <template #cell(fecha_reserva)="items">
            {{ formatDate(items.item.fecha_reserva) }}
            </template>
            <template #cell(turno)="items">
              {{ getTurnoMedicoClass(items.item) }}
              </template>
            <template #cell(importe)="data">
              {{data.item.importe | numeral('0,0.00')}}
            </template>
            <template #cell(accion)="items">
              <div class="col-sm-12">
                <input
                  v-model="selectedItem"
                  type="radio"
                  :id="'radioModal'+items.index"
                  :value="items.item"
                >
              </div>
            </template>
          </b-table>
          <!-- <div class="row">
            <div class="col-sm-8"></div>
            <div class="col-sm-4">
              <b-pagination
                v-model="pagina_actual"
                :total-rows="totalFilas"
                :per-page="por_pagina"
                @input="getReserva"
                class="custom-pagination my-0 float-right"
              ></b-pagination>
            </div>
          </div> -->
          <br>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 text-left"></div>
        <div class="col-sm-6 text-right">
          <button class="btn btn-outline-dark mr-2" @click="cancelarModal()">
            Cancelar
          </button>
          <button class="btn btn-success" @click="confirmarModal()">
            Guardar
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';
import moment from 'moment';
import {
  mapActions,
  mapGetters,
  mapMutations,
} from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'ModalReserva',
  data() {
    return {
      fields: [
        {
          key: 'numero', label: 'N°', thClass: 'table-secondary', thStyle: { width: '8%' },
        },
        {
          key: 'medico', label: 'Profesional', thClass: 'table-secondary', thStyle: { width: '20%' },
        },
        {
          key: 'paciente', label: 'Paciente', thClass: 'table-secondary', thStyle: { width: '20%' },
        },
        {
          key: 'fecha_reserva', label: 'Reserva', thClass: 'table-secondary', thStyle: { width: '12%' },
        },
        {
          key: 'turno', label: 'Turno', thClass: 'table-secondary', thStyle: { width: '12%' },
        },
        {
          key: 'servicio', label: 'Servicio', thClass: 'table-secondary', thStyle: { width: '20%' },
        },
        {
          key: 'importe', label: 'Importe', thClass: 'table-secondary', thStyle: { width: '5%' },
        },
        {
          key: 'accion', label: '', thClass: 'table-secondary', thStyle: { width: '3%' },
        },
      ],
    };
  },
  created() {
    this.getReserva();
  },
  methods: {
    filtrarBusqueda() {
      const DELAY = 400;
      clearTimeout(this.delayTimeout);
      this.delayTimeout = setTimeout(() => {
        this.getReserva();
      }, DELAY);
    },
    formatDate(fecha) {
      if (!fecha) return '';
      const [year, month, day] = fecha.split('-');
      return `${day}/${month}/${year}`;
    },
    cancelarModal() {
      this.$bvModal.hide('modal-reserva');
    },
    async confirmarModal() {
      if (!this.selectedItem) {
        util.showNotify('Debe seleccionar una reserva', 'warn');
        return;
      }
      this.listaTabla = [];
      await this.busquedaAsincronaPacientes(this.selectedItem.paciente);
      this.setActividadEconomicaId(this.selectedItem.actividad_economica_id);
      const RESULT_OK = await this.getDataReservaMedicoFactura();
      this.getDatosPacienteSelected(this.pacienteSelected.id);
      if (RESULT_OK) {
        const data = this.clearParamasListaTabla();
        await this.listaTabla.push(data);
        this.reservaId = this.selectedItem.id;
        const dataAddReserva = {
          numero_reserva: this.selectedItem.numero,
          medico_tercero: this.getMedicoSelected,
        };
        this.$emit('addReserva', dataAddReserva);
        this.selectedItem = null;
      }
      this.confirmarReserva = true;
      this.actualizarInterfaz();
      this.$bvModal.hide('modal-reserva');
    },
    clearParamasListaTabla() {
      const CANTIDAD_DEFAULT = 1;
      const medicoSelected = {
        id: this.selectedItem.medico_id,
        nombre: this.selectedItem.medico,
        numero: this.selectedItem.medico_numero,
      };
      const DATA = {
        numero: this.selectedItem.numero,
        servicio_id: this.selectedItem.tipo_servicio_id,
        descripcion: this.selectedItem.descripcion,
        medicoTempSelected: medicoSelected,
        cantidad: CANTIDAD_DEFAULT,
        precio_unitario: this.selectedItem.importe,
        total: parseInt(CANTIDAD_DEFAULT, 10) * parseFloat(this.selectedItem.importe, 10),
        servicio: this.selectedItem.servicio,
        seguro: 0,
        fecha_turno: this.selectedItem.fecha_reserva,
        hora_turno: this.selectedItem.hora,
        numero_ticket: this.selectedItem.numero_ticket,
        numero_turno: this.selectedItem.numero_turno,
        // turno_medico_id: this.selectedItem.turno_medico_id, // falta
        is_turno_manual: this.selectedItem.is_turno_manual === 1,
        siat_actividad_economica_id: this.selectedItem.siat_actividad_economica_id,
        siat_descripcion_service: this.selectedItem.siat_descripcion_service,
        siat_descripcion_unidad_medida: this.selectedItem.siat_descripcion_unidad_medida,
        siat_tipo_producto_service_id: this.selectedItem.siat_tipo_producto_service_id,
        siat_unidad_medida_id: this.selectedItem.siat_unidad_medida_id,
        idRow: this.selectedItem.id,
        tipo_atencion_id: this.selectedItem.tipo_atencion_id,
        medicoDerivadorSelected: null,
        // actividad_economica_id: this.selectedItem.actividad_economica_id,
        descuento: 0,
      };
      return DATA;
    },
    async getReserva() {
      this.isLoading = true;
      try {
        const REQUEST = {
          state: this.estado,
          filter_key: this.busqueda,
          per_page: this.por_pagina,
          page: this.pagina_actual,
        };
        const RESPONSE = await axios.get('/clinic/consultation/patient/reserva/list', {
          params: REQUEST,
        });
        this.listaReserva = RESPONSE.data.data.reservas.data;

        // Filtrar reservas según el filtro seleccionado
        if (this.filtroSeleccionado === 'clinica') {
          this.listaReserva = this.listaReserva.filter((reserva) => reserva.medico_tercero_id === null);
          this.isFacturaTerceroReserva = false;
          this.isReservaClinica = true;
        } else if (this.filtroSeleccionado === 'medico') {
          this.listaReserva = this.listaReserva.filter((reserva) => reserva.medico_tercero_id !== null);
          this.isFacturaTerceroReserva = true;
          this.isReservaClinica = false;
        }

        this.totalFilas = RESPONSE.data.data.reservas.total;
        this.selectedItem = null;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      } finally {
        this.isLoading = false;
      }
    },
    seleccionarClinica() {
      this.filtroSeleccionado = 'clinica';

      this.getReserva();
    },
    seleccionarMedico() {
      this.filtroSeleccionado = 'medico';
      this.getReserva();
    },
    getTurnoMedicoClass(item) {
      if (item.hora != null) {
        const formattedTime = moment(item.hora, 'HH:mm:ss').format('HH:mm');
        return formattedTime;
      }
      return item.tipo_atencion;
    },
    async busquedaAsincronaPacientes(query) {
      await this.obtenerListaPacientes(query);
      if (this.getPacientes.length > 0) {
        const PACIENTE_SELECTED = this.getPacientes[0];
        this.pacienteSelected = PACIENTE_SELECTED;
      }
    },
    actualizarInterfaz() {
      this.actualizarTotalSeguro();
      this.actualizarTotalSeguroDoble();
      this.actualizarSubTotal();
      // this.getServiciosApi();
      // if (this.getModoEdicion === false) {
      //   if (this.getCheckFacturaTercero === false) {
      //     // si la modalidad es preVENTA NO agregar por defecto y bloquar ajuste y alcontado
      //     if (this.getTipoFactura !== MODALIDAD_FACTURACION.PREVENTA) {
      //       this.agregarMedioPagoContadoPorDefecto();
      //       this.setInsigniasFormaPagoContadoDefault();
      //     }
      //   } else {
      //     this.setClassButton('btn-success');
      //     this.setInsigniasFormaPagoFacturacionTercero();
      //   }
      // }
      // if (this.nroCajas > 1) {
      //   this.setClassButton('btn-danger');
      //   this.nroCajas = 1;
      //   this.modalComboSelected2 = null;
      //   this.modalComboSelected3 = null;
      //   this.monto2 = 0;
      //   this.monto3 = 0;
      //   this.resetAjustesModal();
      //   this.listaBadges = [];
      // }
    },
    async getDataReservaMedicoFactura() {
      try {
        const data = {
          tipo_factura: this.getTipoFactura,
          actividad_economica_id: this.getActividadEconomicaId,
          medico_id: this.selectedItem.medico_tercero_id,
          is_factura: this.isTipoFacturaConFactura,
        };
        this.checkFacturaTercero = false;
        const response = await axios.get('/clinic/consultation/patient/reserva/factura', { params: data });
        this.setNroFactura(response.data.data.numero_factura);
        this.setDocumentoSectorId(response.data.data.documento_sector_id);
        this.setSiatTipoFacturaId(response.data.data.tipo_documento_id);
        const MEDICO_TERCERO = response.data.data.medico_tercero;
        this.setMedicoSelected(MEDICO_TERCERO);
        this.setConfigFacturaElectronicaMedico(response.data.data.configuracion_factura_electronica);
        this.setSiatMedioPagoMedico(response.data.data.siat_medio_pago);
        this.setSiatTipoDocumentoMedico(response.data.data.siat_tipo_documento);
        return true;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
        return false;
      }
    },
    ...mapActions('formFactura', [
      'obtenerListaPacientes',
      'getDatosPacienteSelected',
    ]),
    ...mapActions('addServiceModal', [
      'resetModalWhenClosed',
    ]),
    ...mapMutations('addServiceModal', [
      'setListaTemporal',
      'setListaDetalleTemporal',
      'setActividadEconomicaId',
      'setDocumentoSectorId',
      'setSiatTipoFacturaId',
    ]),
    ...mapActions('detalleFactura', [
      'actualizarSubTotal',
      'actualizarTotalSeguro',
      'actualizarTotalSeguroDoble',
    ]),
    ...mapMutations('formaPagoModal', [
      'agregarMedioPagoContadoPorDefecto',
      'setClassButton',
    ]),
    ...mapActions('detalleFactura', [
      'setInsigniasFormaPagoContadoDefault',
      'setInsigniasFormaPagoFacturacionTercero',
    ]),
    ...mapMutations('formFactura', [
      'setNroFactura',
      'setConfigFacturaElectronicaMedico',
      'setMedicoSelected',
      'setSiatMedioPagoMedico',
      'setSiatTipoDocumentoMedico',
    ]),
  },
  computed: {
    computedFields() {
      return this.fields.map((field) => {
        // Cambia el estilo de cada encabezado según filtroSeleccionado
        if (this.filtroSeleccionado === 'clinica') {
          // eslint-disable-next-line no-param-reassign
          field.thClass = 'table-secondary'; // Color para clínica
        } else if (this.filtroSeleccionado === 'medico') {
          // eslint-disable-next-line no-param-reassign
          field.thClass = 'th-skyblue'; // Color para médico
        }
        return field;
      });
    },
    ...mapFields('modalReserva', [
      'confirmarReserva',
      'selectedItem',
      'busqueda',
      'estado',
      'isLoading',
      'pagina_actual',
      'por_pagina',
      'totalFilas',
      'delayTimeout',
      'filtroSeleccionado',
      'listaReserva',
      'pagina_opciones',
      'reservaId',
      'isFacturaTerceroReserva',
      'isReservaClinica',
    ]),
    ...mapFields('tableAndAddService', ['listaTabla']),
    ...mapFields('formFactura', [
      'pacienteSelected',
      'checkFacturaTercero',
    ]),
    ...mapFields('formaPagoModal', [
      'nroCajas',
      'modalComboSelected2',
      'modalComboSelected3',
      'monto2',
      'monto3',
    ]),
    ...mapGetters('formFactura', [
      'getPacientes',
      'getCheckFacturaTercero',
      'getTipoFactura',
      'getMedicoSelected',
      'isTipoFacturaConFactura',
    ]),
    ...mapGetters('facturacion', [
      'getModoEdicion',
    ]),
    ...mapGetters('addServiceModal', [
      'getActividadEconomicaId',
    ]),
  },
  watch: {
    listaTabla() {
      if (this.listaTabla.length === 0) {
        this.confirmarReserva = false;
        this.reservaId = null;
      }
    },
  },
};
</script>

<style>
.th-skyblue {
  background-color: #87ceeb; /* Sin comillas */
  color: white; /* Sin comillas */
}
.btn-orange {
  color: white;
  background-color: #f4b375;
}
.custom-pagination .page-item.active .page-link {
  background-color: #edbf69 !important; /* Color de fondo del botón activo */
  border-color: #edbf69 !important; /* Color del borde del botón activo */
  color: black !important; /* Color del texto del botón activo */
  z-index: 1;
}
</style>
