<template>
  <div v-if="isLoading">
    <div class="text-center text-danger screen-center">
      <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
      <strong>Cargando...</strong>
    </div>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-sm-4">
        <div class="row">
          <div class="col-sm-8">
          <h3>
            <strong>Atencion Enfermeria</strong>
          </h3>
        </div>
        </div>
        <div class="row">
          <div class="col-sm-6 text-right custom-width">
            <h6>
            Internacion: {{ numeroTratamiento }}
          </h6>
          </div>
        </div>
      </div>
      <div class="col-sm-7 navbar-reubicado">
        <div class="row">
          <div class="offset-sm-4 col-sm-4 bg-sky-light text-white py-2 bottomleft-rounded">
            <div class="row" v-if="paciente.cobertura">
              <div class="col-sm-12">
                <b>Seguro:</b> {{ paciente.cobertura }}
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <b>Paciente:</b> {{ paciente.full_name }}
              </div>
            </div>
          </div>
          <div class="col-sm-4 pl-0 bg-sky-light text-white py-2 bottomright-rounded">
            <div class="row" v-if="paciente.numero_poliza">
              <div class="col-sm-12">
                <b>
                  Cod.:</b> {{ paciente.numero_poliza }} -
                <b v-show="paciente.tipo_familiar" class="text-success">
                  ({{ paciente.tipo_familiar }})
                </b>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-5 pr-0">
                <b>HC.:</b> {{ paciente.numero }}
              </div>
              <div class="col-sm-7 pl-0">
                Edad: {{ paciente.edad }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-6">
                <div class="breadcrumb mb-2 custom-top-padding float-left">
                  <button
                    class="btn btn-info"
                    @click="handleRegresar"
                  >
                    Regresar
                  </button>
                </div>
              </div>
              <div class="col-sm-3 offset-sm-3">
                <div class="text-right">
                  <label class="switch switch-success mr-3">
                    <input
                    id="filterbyState"
                      type="checkbox"
                      :checked="estado"
                      v-model="estado"
                    >
                    <span class="slider"></span>
                    <span>{{ labelSwitch }}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div v-if="estado">
              <div class="row mx-0">
                <InfoEnfermeriaItem
                  main-text="Indicación"
                />
                <div class="row">
                  <div
                    v-for="(item, index) in tipoMonitoreo.filter((monitoreo) =>
                      monitoreo.hasPrintSignosVitales ||
                      monitoreo.hasPrintCambioPosicion ||
                      monitoreo.hasPrintBalanceHidrico
                    )"
                    :key="index"
                    class="form-group pt-2"
                  >
                    <button class="btn btn-secondary mr-3" @click="imprimirDatos(item)">
                      {{ item.nombre }}
                    </button>
                  </div>
                </div>

                <!-- <InfoEnfermeriaItem
                  :type-item="2"
                  main-text="Productos"
                  medico-name="Dr. Maria Ledesma"
                  @click.native="openModalIndicacionMedica"
                />
                <InfoEnfermeriaItem
                  :type-item="3"
                  main-text="Productos"
                  medico-name="Dr. Maria Ledesma"
                  @click.native="openModalIndicacionMedica"
                /> -->
            </div>
            </div>
            <div v-else>
                <div class="row mb-1">
                  <div class="col-sm-3">
                    <div class="btn-group">
                      <span class="pt-2 mr-1">Mostrar </span>
                      <b-form-select v-model="porPagina"
                       :options="pagina_opciones"
                       @change="listarRegistros">
                      </b-form-select>
                      <span class="pt-2 ml-1"> Registros</span>
                    </div>
                  </div>
                  <div class="col-sm-3 offset-sm-6 text-right">
                    <div class="form-group row">
                      <div class="col-sm-4"></div>
                      <div class="col-sm-8">
                        <input type="text" class="form-control"
                         v-model="busqueda"
                         autocomplete="off"
                         placeholder="Buscar"
                         @keyup="filtrarBusqueda">
                      </div>
                    </div>
                  </div>
                </div>
                <b-table
                  class="table table-striped table-hover"
                  :fields="fields"
                  :items="listaTablas"
                  :busy="isLoading"
                  :per-page="porPagina"
                >
                </b-table>
                <div class="row">
                  <div class="col-sm-8"></div>
                  <div class="col-sm-4">
                    <b-pagination
                      v-model="paginaActual"
                      :total-rows="totalFilas"
                      :per-page="porPagina"
                      @input="listarRegistros"
                      class="my-0 float-right"
                    ></b-pagination>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <TheModalInformeCirugia />
  </div>
</template>

<script>
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';
import { mapActions, mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import InfoEnfermeriaItem from '../components/InfoEnfermeriaItem.vue';
import TheModalInformeCirugia from '../components/TheModalInformeCirugia.vue';

export default {
  name: 'AtencionEnfermeria',
  components: {
    InfoEnfermeriaItem,
    TheModalInformeCirugia,
  },
  data() {
    return {
      pagina_opciones: [10, 20, 50, 100],
      isLoading: false,
      fields: [
        {
          key: 'numero', label: 'N°', thClass: 'th-custom-color', thStyle: { width: '5%' },
        },
        {
          key: 'fecha_creado', label: 'F/Creado', class: 'text-center', thClass: 'th-custom-color',
        },
        {
          key: 'emitido', label: 'Emitido', class: 'text-center', thClass: 'th-custom-color',
        },
        {
          key: 'fecha_cierre', label: 'F/Cierre', thClass: 'th-custom-color',
        },
        {
          key: 'cerrado_por', label: 'Cerrado por:', class: 'px-2', thClass: 'th-custom-color',
        },
        {
          key: 'tipo', label: 'Tipo', thClass: 'th-custom-color', class: 'text-center',
        },
      ],
      pacienteData: null,
      id: null,
      activoPrint: false,
    };
  },
  created() {
    const { params } = this.$route;
    const dto = {
      id: atob(params.id),
      paciente_id: atob(params.paciente_id),
    };
    this.id = dto.id;
    this.pacienteData = dto;
    this.listarRegistros();
  },
  methods: {
    handleRegresar() {
      this.$router.go(-1);
    },
    openModalInformeCirugia() {
      this.$bvModal.show('modal-informe-cirugia');
    },
    async listarRegistros() {
      this.isLoading = true;
      const RESULT_OK = await this.getDatoInicial(this.pacienteData);
      this.tipoMonitoreo.forEach((item) => {
        if (item.id === 1) {
          // eslint-disable-next-line no-param-reassign
          item.hasPrintSignosVitales = this.hasPrintSignosVitales;
        } else if (item.id === 2) {
          // eslint-disable-next-line no-param-reassign
          item.hasPrintCambioPosicion = this.hasPrintCambioPosicion;
        } else if (item.id === 3) {
          // eslint-disable-next-line no-param-reassign
          item.hasPrintBalanceHidrico = this.hasPrintBalanceHidrico;
        }
      });
      if (RESULT_OK) {
        this.isLoading = false;
      }
    },
    filtrarBusqueda() {
      // this.listarRegistros();
    },
    imprimirDatos(item) {
      this.registroMonitoreo(this.id, item);
      this.getMonitoreo(this.id, item);
    },
    async registroMonitoreo(id, item) {
      try {
        const RESPONSE = await axios.post('/clinic/tratamientos/enfermeria/registro_monitoreo', {
          tratamiento_id: id,
          tipo_monitoreo_id: item.id,
        });
        util.showNotify(RESPONSE.data.message, 'success');
      } catch (error) {
        const FALLO_VALIDACION = 422;
        if (error.response.status !== FALLO_VALIDACION) {
          util.showNotify(error.response.data.message, 'error');
        }
      }
    },
    async getMonitoreo(id, item) {
      try {
        const RESPONSE = await axios.get('/clinic/tratamientos/enfermeria/hoja_monitoreo/print', {
          params: {
            tratamiento_id: id,
            tipo_monitoreo_id: item.id,
          },
          responseType: 'blob',
        });
        const BLOB = new Blob([RESPONSE.data], { type: 'application/pdf' });
        const DOWNLOAD_URL = URL.createObjectURL(BLOB);
        window.open(DOWNLOAD_URL, '_blank');
      } catch (error) {
        const FALLO_VALIDACION = 422;

        // Si ocurre un error, mostramos un mensaje claro al usuario
        if (error.response.status !== FALLO_VALIDACION) {
          util.showNotify(error.response?.data?.message || 'Error al obtener el PDF.', 'error');
        }
      }
    },
    ...mapActions('atencionEnfermeria', [
      'getDatoInicial',
      'resetDataListarAtencionEnfermeria',
    ]),
  },
  watch: {
    porPagina() {
      this.listarRegistros();
    },
    paginaActual() {
      this.listarRegistros();
    },
  },
  computed: {
    labelSwitch() {
      if (this.estado) {
        return 'Pendiente';
      }
      return 'Atendidos';
    },
    ...mapState('atencionEnfermeria', [
      'listaTablas',
      'numeroTratamiento',
      'tipoMonitoreo',
      'hasPrintSignosVitales',
      'hasPrintCambioPosicion',
      'hasPrintBalanceHidrico',
    ]),
    ...mapFields('atencionEnfermeria', [
      'estado',
      'porPagina',
      'busqueda',
      'paginaActual',
      'totalFilas',
      'paciente',
    ]),
  },
  beforeDestroy() {
    this.resetDataListarAtencionEnfermeria();
  },
};
</script>

<style scoped>
@media (min-width: 1200px) and (max-width: 1300px) {
  .custom-width {
    flex: 3 0 50%; /* Similar a col-sm-6 */
    max-width: 61%;
  }
}
.custom-top-padding {
  padding-top: 5.53px;
}
.bg-sky-light
{
  background:#a6d2eb;
}
.navbar-reubicado
{
  transform: translate(0px, -32px);
}
.bottomleft-rounded {
  border-bottom-left-radius: 15px;
}
.bottomright-rounded {
  border-bottom-right-radius: 15px;
}
</style>
