<template>
    <div v-if="isLoading">
      <div class="text-center text-danger screen-center">
        <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
        <strong>Cargando...</strong>
      </div>
    </div>
    <div v-else>
      <div>
        <div class="row mr-0">
          <div class="col-sm-3">
            <div class="breadcrumb">
              <h1>
                Internacion
                <span class="h4 pl-2" v-if="edit">
                  N°: 34/24
                </span>
              </h1>
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-12">
            <div class="card text-left">
              <div class="card-header">
                <div class="row">
                  <div class="col-sm-5">
                      <button class="btn btn-white border border-light mr-2">
                        Regresar
                      </button>
                      <button type="button" class="btn btn-dark mr-2" @click="storeHistorialClinico">
                          Guardar
                        </button>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="row pt-3">
                      <div class="col d-flex" v-if="edit">
                        <strong>Fecha: </strong>
                        <div class="px-3">
                          22/07/2024 - Iniciado 17:56
                        </div>
                      </div>
                    </div>
                    <div class="row pb-3">
                      <div class="col-12">
                        <strong>Paciente:</strong>
                        {{dataPaciente.full_name}}
                      </div>
                    </div>
                    <div class="row mx-2">
                        <div class="form-group">
                            <b><font size=3>Motivo de Internacion:</font></b>
                            <textarea name="" v-model="historialClinico.motivo_consulta"
                              cols="90" rows="4" class="form-control"
                            >
                            </textarea>
                          </div>
                    </div>
                    <div class="row mx-2">
                        <div class="form-group pt-2">
                            <b><font size=3>Enfermedad Actual:</font></b>
                            <textarea name=""
                             v-model="historialClinico.enfermedad_actual"
                              cols="90" rows="7" class="form-control"
                            >
                            </textarea>
                          </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="row mx-2">
                        <div class="form-group pt-2">
                            <b><font size=3>Examen Fisico:</font></b>
                            <textarea name=""
                             v-model="historialClinico.examen_fisico"
                              cols="90" rows="7" class="form-control"
                            >
                            </textarea>
                          </div>
                    </div>
                    <div class="row mx-2">
                        <div class="form-group pt-2">
                            <b><font size=3>Tratante:</font></b>
                            <textarea name=""
                             v-model="historialClinico.tratante"
                              cols="90" rows="7" class="form-control"
                            >
                            </textarea>
                          </div>
                    </div>
                  </div>
                </div>
                <div class="container-fluid btnOrange">
                    <div class="row">
                        <h4 class="mt-3 mx-3"><strong>ANTECEDENTES GINECO OBSTETRICOS</strong></h4>
                    </div>
                    <div class="row mx-3 mt-2 mb-5">
                      <div class="col-sm-2 d-flex align-items-center custom-col">
                            <label for="fum" class="mr-2"><b>FUM:</b> </label>
                            <date-picker
                            class="col-sm-12"
                            v-model="historialClinico.antecedente_gineco_obstetrico.fum"
                            lang="es"
                            format="DD/MM/YYYY"
                            type="date"
                            value-type="date"
                          ></date-picker>
                        </div>

                        <div class="col-sm-3 d-flex align-items-center custom-col">
                            <label for="fpp" class="mr-2"><b>FPP:</b> </label>
                            <date-picker
                            class="col-sm-12"
                            v-model="historialClinico.antecedente_gineco_obstetrico.fpp"
                            lang="es"
                            format="DD/MM/YYYY"
                            type="date"
                            value-type="date"
                          ></date-picker>
                        </div>
                        <div class="col-sm-2 d-flex align-items-center custom-col">
                            <label for="g" class="mr-2"><b>G: </b></label>
                            <textarea id="g"
                            v-model="historialClinico.antecedente_gineco_obstetrico.gestaciones"
                             class="form-control" rows="2" style="resize: none;"></textarea>
                        </div>
                        <div class="col-sm-2 d-flex align-items-center custom-col">
                            <label for="p" class="mr-2"><b>P: </b></label>
                            <textarea id="p"  class="form-control" rows="2" style="resize: none;" v-model="historialClinico.antecedente_gineco_obstetrico.partos"></textarea>
                        </div>
                        <div class="col-sm-2 d-flex align-items-center custom-col">
                            <label for="ab" class="mr-2"><b>AB:</b></label>
                            <textarea id="ab"
                            v-model="historialClinico.antecedente_gineco_obstetrico.abortos" class="form-control" rows="2" style="resize: none;"></textarea>
                        </div>
                        <div class="col-sm-2 d-flex align-items-center custom-col">
                            <label for="c" class="mr-2"><b>C:</b></label>
                            <textarea id="c"
                             v-model="historialClinico.antecedente_gineco_obstetrico.cesareas"
                            class="form-control"
                            rows="2" style="resize: none;"></textarea>
                        </div>
                        <div class="col-sm-2 d-flex align-items-center custom-col">
                            <label for="pap" class="mr-2"><b>PAP:</b></label>
                            <textarea id="pap" v-model="historialClinico.antecedente_gineco_obstetrico.pap" class="form-control" rows="2" style="resize: none;"></textarea>
                        </div>
                      </div>    <div class="container-fluid mt-3">
                        <div class="row mx-5">
                          <div class="col-md-5 mr-5 mb-4">
                            <div class="form-group">
                              <label for="craneo"><b>Craneo</b></label>
                              <input type="text" v-model="historialClinico.antecedente_gineco_obstetrico.craneo" class="form-control" id="craneo">
                            </div>
                            <div class="form-group">
                              <label for="cara"><b>Cara</b></label>
                              <input type="text" v-model="historialClinico.antecedente_gineco_obstetrico.cara" class="form-control" id="cara">
                            </div>
                            <div class="form-group">
                              <label for="cuello"><b>Cuello</b></label>
                              <input type="text" v-model="historialClinico.antecedente_gineco_obstetrico.cuello" class="form-control" id="cuello">
                            </div>
                            <div class="form-group">
                              <label for="torax"><b>Torax</b></label>
                              <input type="text" v-model="historialClinico.antecedente_gineco_obstetrico.torax" class="form-control" id="torax">
                            </div>
                            <div class="form-group">
                              <label for="corazon"><b>Corazon</b></label>
                              <input type="text" v-model="historialClinico.antecedente_gineco_obstetrico.corazon" class="form-control" id="corazon">
                            </div>
                            <div class="form-group">
                              <label for="pulmones"><b>Pulmones</b></label>
                              <input type="text" v-model="historialClinico.antecedente_gineco_obstetrico.pulmon" class="form-control" id="pulmones">
                            </div>
                          </div>
                          <div class="col-md-5 ml-5 mb-4">
                            <div class="form-group">
                              <label for="abdomen"><b>Abdomen</b></label>
                              <input type="text" v-model="historialClinico.antecedente_gineco_obstetrico.abdomen" class="form-control" id="abdomen">
                            </div>
                            <div class="form-group">
                              <label for="genitales"><b>Genitales</b></label>
                              <input type="text" v-model="historialClinico.antecedente_gineco_obstetrico.genitales" class="form-control" id="genitales">
                            </div>
                            <div class="form-group">
                              <label for="extremindades"><b>Extremidades</b></label>
                              <input type="text" v-model="historialClinico.antecedente_gineco_obstetrico.extremidades" class="form-control" id="extremindades">
                            </div>
                            <div class="form-group">
                              <label for="sistema"><b>Sistema Nervioso Central</b></label>
                              <input type="text" v-model="historialClinico.antecedente_gineco_obstetrico.sistema_nervioso_central" class="form-control" id="sistema">
                            </div>
                            <div class="form-group">
                              <label for="indicaciones"><b>Indicaciones</b></label>
                              <input type="text" v-model="historialClinico.antecedente_gineco_obstetrico.indicaciones" class="form-control" id="indicaciones">
                            </div>
                          </div>
                        </div>
                      </div>
                </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
<script>
import { util } from '@/plugins/util';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';

export default {
  name: 'historia-clinica',
  props: {
    dataPaciente: {
      type: Object, // Cambié a objeto porque parece que incluye más datos
      required: false,
      default: null,
    },
    edit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    DatePicker,
  },
  data() {
    return {
      isLoading: false,
      historialClinico: {
        motivo_consulta: '',
        enfermedad_actual: '',
        examen_fisico: '',
        tratante: '',
        antecedente_gineco_obstetrico: {
          fum: '',
          fpp: '',
          gestaciones: null,
          partos: null,
          abortos: null,
          cesareas: null,
          pap: '',
          craneo: '',
          cara: '',
          cuello: '',
          torax: '',
          corazon: '',
          pulmones: '',
          abdomen: '',
          genitales: '',
          extremidades: '',
          sistema_nervioso_central: '',
          indicaciones: '',
        },
      },
    };
  },
  methods: {
    storeHistorialClinico() {
      this.$emit('historial-clinico-enviado', this.historialClinico);
      util.showNotify('Guardado', 'success');
      this.$router.go(-1);
      this.isLoading = false;
    },

    // Método para inicializar datos (si se edita un registro existente)
    loadHistorialClinico() {
    },
  },

};
</script>

  <style scoped>
  .custom-col {
    flex: 0 0 12.5%;
    max-width: 15.5%;
  }
.btnOrange {
    background-color: #fdd6b1;
  }
  </style>
