import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';
import { MODALIDAD_FACTURACION, TIPO_EMISION } from '@/utils/globalConventions';
import validarParametroFacturacionSiat from '@/store/modules/facturacion/utils/siatValidations';

function filterModalidad(
  modalidades,
  extrafacturaElectronica,
  extraPreventa,
  sucursalId,
  hasOptionSinFactura,
) {
  const sucursal = modalidades.find((el) => el.sucursal_id === sucursalId) ?? null;
  if (sucursal === null) {
    if (hasOptionSinFactura) {
      return [{ id: '', nombre: 'Sin Factura' }];
    }
    return [];
  }
  if (!extrafacturaElectronica) {
    const indexComputarizaLinea = sucursal.modalidades.findIndex((mf) => mf.id === 3);
    const indexElectronicaLinea = sucursal.modalidades.findIndex((mf) => mf.id === 4);
    if (indexComputarizaLinea >= 0) {
      sucursal.modalidades.splice(indexComputarizaLinea, 1);
    }
    if (indexElectronicaLinea >= 0) {
      sucursal.modalidades.splice(indexElectronicaLinea, 1);
    }
  }
  if (!extraPreventa) {
    const INDEX_PREVENTA = sucursal.modalidades.findIndex((mf) => mf.id === 5);
    if (INDEX_PREVENTA >= 0) {
      sucursal.modalidades.splice(INDEX_PREVENTA, 1);
    }
  }
  return sucursal.modalidades;
}
function filterTipoEmision(lista, extrafacturaElectronica) {
  let tipoEmisions = [];
  if (extrafacturaElectronica) {
    tipoEmisions = lista.filter((item) => (
      item.cod_clasificador === 1 || item.cod_clasificador === 2
      // en linea y fuera de linea
    ));
  }
  return tipoEmisions;
}
export default async function callCreateApi({ rootState, commit, rootGetters }) {
  try {
    const RESPONSE = await axios.get('/clinic/invoicing/create');
    const { aseguradoras } = RESPONSE.data.data;
    const { medicos } = RESPONSE.data.data;
    const { monedas } = RESPONSE.data.data;
    const { sucursal } = RESPONSE.data.data;
    const FORMAS_DE_PAGO = RESPONSE.data.data.tipo_forma_pagos;
    const MEDIOS_DE_PAGO = RESPONSE.data.data.tipo_medio_pagos;
    const CATEGORIA_SERVICIOS = RESPONSE.data.data.categoria_servicios;
    const MEDIO_PAGO_CREDITO = RESPONSE.data.data.medio_pago_credito;
    const TIPO_DOCUMENTOS = RESPONSE.data.data.tipo_documentos;
    const HAS_OPTION_SIN_FACTURA = RESPONSE.data.data.has_option_sin_factura;
    const SERVICIOS_COMPLEMENTARIOS = RESPONSE.data.data.nombre_parametro_servicio_complementario;
    const medicosTratantes = RESPONSE.data.data.tratantes;
    const MEDICO_DERIVADOR = RESPONSE.data.data.derivador;

    const LISTA_CONTINGENCIAS = validarParametroFacturacionSiat(
      RESPONSE.data.data.tipo_factura_contingencias,
      false,
      rootState.main.extrafacturaElectronica,
    );
    const LISTA_TIPO_EMISIONS = validarParametroFacturacionSiat(
      RESPONSE.data.data.tipo_emisions,
      true,
      rootState.main.extrafacturaElectronica,
    );
    const LISTA_TIPO_EMISIONS_FILTERED = filterTipoEmision(
      LISTA_TIPO_EMISIONS,
      rootState.main.extrafacturaElectronica,
    );
    const TIPO_EMISION_SELECTED = LISTA_TIPO_EMISIONS_FILTERED.find((el) => (
      el.cod_clasificador === TIPO_EMISION.FUERA_LINEA
    )) ?? null;

    const LISTA_PUNTO_VENTAS = validarParametroFacturacionSiat(
      RESPONSE.data.data.siat_punto_ventas,
      false,
      rootState.main.extrafacturaElectronica,
    );
    if (HAS_OPTION_SIN_FACTURA) {
      RESPONSE.data.data.modalidades_configuracion.forEach((el) => {
        el.modalidades.push({ id: MODALIDAD_FACTURACION.SIN_FACTURA, nombre: 'Sin Factura' });
      });
    }
    const modalidadesBySucursal = RESPONSE.data.data.modalidades_configuracion;
    let modalidadesFacturacion = filterModalidad(
      modalidadesBySucursal,
      rootState.main.extrafacturaElectronica,
      rootGetters['main/hasPermissionPreVenta'],
      sucursal.id,
      HAS_OPTION_SIN_FACTURA,
    );
    if (rootGetters['main/isBloqueadoFacturaManual']) {
      modalidadesFacturacion = modalidadesFacturacion.filter((mod) => (
        mod.id !== MODALIDAD_FACTURACION.MANUAL
      ));
    }
    const MEDIO_PAGO_AJUSTE_ANTICIPO = RESPONSE.data.data.medioPagoAjuste;
    const LABORATORY = SERVICIOS_COMPLEMENTARIOS.find((el) => el.servicio_complementario_categoria_id === 9) ?? null;
    if (LABORATORY != null) {
      commit('setHasEnabledLaboratory', (LABORATORY.estado === 1));
    } else {
      commit('setHasEnabledLaboratory', false);
    }

    const MONEDA_SECUNDARIA = monedas.find((m) => m.is_principal === 0);
    const TC_MONEDA_SECUNDARIA = MONEDA_SECUNDARIA.tc;
    const TC = TC_MONEDA_SECUNDARIA;

    commit('setSucursal', sucursal);
    commit('formaPagoModal/setMedioPagoCredito', MEDIO_PAGO_CREDITO, { root: true });
    commit('formFactura/setTipoDocumentos', TIPO_DOCUMENTOS, { root: true });
    commit('formFactura/setIndentificacionSelected', TIPO_DOCUMENTOS[0], { root: true });
    commit('formFactura/setTipoContingencias', LISTA_CONTINGENCIAS, { root: true });
    commit('formFactura/setTipoEmisions', LISTA_TIPO_EMISIONS_FILTERED, { root: true });
    commit('formFactura/setTipoEmisionSelected', TIPO_EMISION_SELECTED, { root: true });
    commit('setPuntoVentas', LISTA_PUNTO_VENTAS);
    commit('formFactura/setHasOptionSinFactura', HAS_OPTION_SIN_FACTURA, { root: true });
    commit('formFactura/setModalidadesFacturas', modalidadesFacturacion, { root: true });
    commit('formFactura/setTipoFactura', modalidadesFacturacion[0].id, { root: true });
    commit('formFactura/setComboAseguradora', aseguradoras, { root: true });
    commit('formFactura/setMedicos', medicos, { root: true });
    commit('formFactura/setMedicosTratantes', medicosTratantes, { root: true });
    commit('formFactura/cargarComboMoneda', monedas, { root: true });
    commit('formaPagoModal/setTipoFormaPagos', FORMAS_DE_PAGO, { root: true });
    commit('formaPagoModal/setComboModalMedioPago', MEDIOS_DE_PAGO, { root: true });
    commit('addServiceModal/setCategoriaServicios', CATEGORIA_SERVICIOS, { root: true });
    commit('addServiceModal/setCategoriaServiciosSelected', CATEGORIA_SERVICIOS[0], { root: true });
    commit('formFactura/setCheckValorCero', 0, { root: true });
    commit('formaPagoModal/setMedioPagoAjusteAnticipo', MEDIO_PAGO_AJUSTE_ANTICIPO, { root: true });
    commit('formFactura/setMedicoDerivador', MEDICO_DERIVADOR, { root: true });
    commit('formFactura/cargarTipoCambio', TC, { root: true });
  } catch (error) {
    util.showNotify(error.response.data.message, 'error');
  }
}
