<template>
    <div>
      <div class="row">
        <div :class="colClass">
          <button
            :class="['btn', 'px-2', 'py-1', 'd-flex', 'justify-content-center', 'text-center', buttonClass]"
            style="white-space: normal;"
            @click="abrirModal()"
          >
            {{ buttonText }}
          </button>
        </div>
        <div v-if="has_Admision" class="col-sm-3">
          <button class="btn px-2 py-1 btn-secondary" @click="PdfHojaAdmision()">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-printer" viewBox="0 0 16 16">
              <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
              <path
                d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2
                2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2
                2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2
                2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2
                2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1
                1 0 0 1 1 1z"
              />
            </svg>
          </button>
        </div>
        <div v-if="has_Admision" class="col-sm-3">
          <button class="btn px-2 py-1 btn-secondary nav-icon i-File-Download font-weight-bold"
          @click="imprimirPdfHojaAdmision()"></button>
        </div>
      </div>
      <b-modal
        id="modal-hoja-admision"
        size="custom-lg-max"
        :title="tituloModal"
        hide-footer
        ref="modalHojaAdmision"
        centered
        header-class="bg-gray-300"
        >
        <div class="container-fluid">
            <div class="row align-items-center mb-3">
              <div class="row text-right mr-1 mb-1">
                <div class="col-sm-6">
                  <template v-for="(tipo, index) in buttonPacienteFamiliars">
                    <button class="btn btn-secondary mr-1"
                      :key="index"
                      @click="changeInvoiceType(index + 1)"
                      v-if="tipo.id === tipoButton">
                      {{ tipo.nombre }}
                    </button>
                  </template>
                </div>
                <div class="col-sm-6">
                  <button v-show="isContacto"
                    class="btn btn-success"
                      @click="getDataCreateContacto"
                    :disabled="isEdit">+</button>
                </div>
              </div>
              <template v-if="newContacto && isContacto">
                <div class="row text-right mb-1"
                  v-for="(contacto, key) in contactosNewPacientes"
                  v-bind:item="contacto"
                  v-bind:index="key"
                  v-bind:key="key">
                  <div class="col-sm-6">
                    <div class="row mr-1">
                      <div class="col-sm-4">
                        <multiselect
                          v-model="contacto.parentescoSelected"
                          :options="parentescos"
                          label="nombre"
                          track-by="id"
                          placeholder="Seleccione"
                        />
                      </div>
                      <div class="col-sm-4">
                        <input v-model="contacto.nombres"
                        type="text" class="form-control" autocomplete="off"
                        placeholder="Nombre y Apellidos">
                      </div>
                      <div class="col-sm-4">
                        <input type="email" v-model="contacto.mail"
                          class="form-control" autocomplete="off"
                          placeholder="email">
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="row mr-1">
                      <div class="col-sm-2">
                        <multiselect v-model="contacto.paisSelected" :options="paises"
                          :allow-empty="false"
                          :show-labels="false" :searchable="false"
                          :close-on-select="true"
                          label="descripcion"
                          track-by="descripcion"
                          @input="setCodigoPais(key)">
                          <template slot="singleLabel" slot-scope="props">
                            <img class="option__image"
                              :src="props.option.img"
                              :alt="props.option.descripcion"
                              width="26px">
                          </template>
                          <template slot="option" slot-scope="props">
                            <img class="option__image"
                              :src="props.option.img"
                              :alt="props.option.descripcion"
                              width="26px">
                          </template>
                        </multiselect>
                      </div>
                      <div class="col-sm-4">
                        <input type="text"
                        class="form-control"
                        :placeholder="'+'+contacto.codigoPais"
                        v-model="contacto.celular"
                        autocomplete="off"
                        @keydown="validarCaracter"
                        @blur="reValidarCelular(key)">
                      </div>
                      <div class="col-sm-4">
                        <input type="text" v-model="contacto.ci"
                        class="form-control" autocomplete="off" placeholder="CI">
                      </div>
                      <div class="col-sm-2">
                        <i class="nav-icon i-Close-Window text-danger font-weight-bold"
                          style="cursor: pointer"
                          @click="eliminarItemContacto(key)"
                          v-if="contactosNewPacientes.length > 1"
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div v-if="familiarPaciente || !isEdit" class="col-sm-9">
                  <!-- multiselect para familiar -->
                  <multiselect
                  v-if="isFamiliar"
                  v-model="familiarSelect"
                  :options="familiar"
                  label="full_name"
                  placeholder="Seleccione un familiar"
                  track-by="id"
                  :searchable="true"
                  />
                  <!-- multiselect para contactos -->
                  <multiselect
                    v-if="isContacto"
                    v-model="familiarSelect"
                    :options="contactos"
                    label="nombres"
                    placeholder="Seleccione un contacto"
                    track-by="id"
                    :searchable="true"
                    :loading="isLoading"
                    @search-change="busquedaAsincronaContactos"
                    >
                    <span slot="caret" v-if="!(familiarSelect===null)"></span>
                    <span slot="noResult">Sin Resultados</span>
                    <span slot="noOptions">Lista Vacia</span>
                    <template slot="option" slot-scope="props">
                      {{ props.option.nombres }}
                    </template>
                  </multiselect>
                </div>
                <div v-else>
                  <label for="" style="color: red;"> No tiene un Familiar Asignado</label>
                </div>
              </template>
            </div>
            <div v-if="familiarPaciente || !isEdit" class="row justify-content-end">
              <div class="col-auto">
                <button class="btn btn-success" @click="guardarFamiliar()">
                  Guardar
                </button>
              </div>
            </div>
          </div>
      </b-modal>
    </div>
  </template>
<script>
import { util } from '@/plugins/util';
import { mapActions, mapState } from 'vuex';
import axios from '@/modules/common/axiosERP';
import multiselect from '@/components/util/vue-multiselect2';
import { mapFields } from 'vuex-map-fields';
import { TIPO_BUTTON_ADMISION } from '@/utils/globalConventions';

export default {
  name: 'ModalHojaAdmision',
  components: {
    multiselect,
  },
  props: {
    isEdit: {
      type: Boolean,
    },
    has_Admision: {
      type: Boolean,
    },
    paciente_id: {
      type: Number,
    },
    tratamiento_id: {
      type: [Number, String],
      require: true,
    },
    familiarPaciente: {
      type: Object,
    },
  },
  data() {
    return {
      buttonPacienteFamiliars: [
        {
          id: 1,
          nombre: 'Familiar',
        },
        {
          id: 2,
          nombre: 'Contacto',
        },
      ],
      tipoButton: 1,
      delayTimeout: null,
    };
  },
  created() {
    if (this.isEdit) {
      this.cargar();
      this.buscarFamiliar();
    }
  },
  methods: {
    async cargar() {
      await this.getFamiliares(this.paciente_id);
    },
    async abrirModal() {
      this.newContacto = false;
      if (this.paciente_id) {
        await this.cargar();
        if (this.isEdit) {
          this.buscarFamiliar();
        }
        this.$refs.modalHojaAdmision.show();
      } else {
        util.showNotify('Seleccione un paciente', 'warn');
      }
    },
    buscarFamiliar() {
      if (this.familiarPaciente) {
        // FAMILIAR
        if (this.familiarPaciente.familiar_id) {
          this.tipoButton = TIPO_BUTTON_ADMISION.FAMILIAR;
          const familiarEncontrado = this.familiar.find(
            (familiar) => familiar.id === this.familiarPaciente.familiar_id,
          );
          if (familiarEncontrado) {
            this.familiarSelect = familiarEncontrado;
          }
        }
        // CONTACTOS
        if (this.familiarPaciente.contacto_paciente_id) {
          this.tipoButton = TIPO_BUTTON_ADMISION.CONTACTO; // contacto
          const familiarEncontrado = this.contactos.find(
            (contacto) => contacto.id === this.familiarPaciente.contacto_paciente_id,
          );
          if (familiarEncontrado) {
            this.familiarSelect = familiarEncontrado;
          } else {
            const obj = {
              id: this.familiarPaciente.contacto_paciente_id,
              nombres: this.familiarPaciente.contacto,
            };
            this.familiarSelect = obj;
          }
        }
      }
    },
    async imprimirPdfHojaAdmision() {
      const TRATAMIENTO_ID = this.tratamiento_id;
      const params = {
        familiar_id: this.familiarSelect.id, // Aquí se incluye el id del familiar
      };
      try {
        const RESPONSE = await axios.get(`/clinic/tratamientos/hoja_admision/print/${TRATAMIENTO_ID}`, {
          params,
          responseType: 'blob',
        });
        const BLOB = new Blob([RESPONSE.data], { type: 'application/pdf' });
        const DOWNLOAD_URL = URL.createObjectURL(BLOB);
        window.open(DOWNLOAD_URL, '_blank');
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async PdfHojaAdmision() {
      const TRATAMIENTO_ID = this.tratamiento_id;
      const params = {
        familiar_id: this.familiarSelect?.id || null, // Asegurarse de que familiarSelect no sea null
      };

      try {
        const response = await axios.get(`/clinic/tratamientos/hoja_admision/print/${TRATAMIENTO_ID}`, {
          params,
          responseType: 'blob',
        });

        if (response && response.data) {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const pdfUrl = URL.createObjectURL(blob);

          const iframe = document.createElement('iframe');
          iframe.src = pdfUrl;
          iframe.style.display = 'none';
          document.body.appendChild(iframe);

          // Esperar a que se cargue el PDF en el iframe antes de imprimir
          iframe.onload = function () {
            iframe.contentWindow.print();
          };
        } else {
          util.showNotify('La respuesta no contiene datos válidos', 'error');
        }
      } catch (error) {
        console.error('Error al descargar el PDF:', error);
        util.showNotify('Hubo un problema al obtener el PDF', 'error');
      }
    },
    async storeContacto() {
      if (this.validateStoreContacto()) {
        const DATA = {
          paciente_id: this.paciente_id,
          pacienteContactos: this.clearParametrosContactoNew(),
        };
        await this.storeContactoNew(DATA);
      }
    },
    clearParametrosContactoNew() {
      // let contactos = [];
      this.contactosNewPacientes.forEach((element, index) => {
        this.contactosNewPacientes[index].pais_id = element.paisSelected.id;
        this.contactosNewPacientes[index].parentesco_id = element.parentescoSelected.id;
        this.contactosNewPacientes[index].telefono = element.celular;
        delete this.contactosNewPacientes[index].paisSelected;
        delete this.contactosNewPacientes[index].parentescoSelected;
        // delete this.contactosNewPacientes[index].celular;
      });
      return this.contactosNewPacientes;
    },
    async guardarFamiliar() {
      this.isAgregar = true;
      if (this.newContacto) {
        await this.storeContacto();
      } else if (!this.familiarSelect) {
        util.showNotify('Seleccione un Familiar para guardar', 'warn');
        this.isAgregar = false;
        return;
      }
      if (this.isAgregar) {
        const data = {
          data_familiar: this.familiarSelect,
          tipo_familiar: this.tipoButton,
        };
        this.$bvModal.hide('modal-hoja-admision');
        this.$emit('familiar-seleccionado', data);
      }
    },
    ...mapActions('modalHojaAdmision', [
      'getFamiliares',
      'getAllContactos',
      'createContacto',
      'storeContactoNew',
    ]),
    validateStoreContacto() {
      let validate = true;
      this.contactosNewPacientes.forEach((element, index) => {
        if (!element.paisSelected) {
          util.showNotify(`Seleccione un pais para la fila ${index + 1}`, 'warn');
          validate = false;
        }
        if (!element.parentescoSelected) {
          util.showNotify(`Seleccione un parentesco para la fila ${index + 1}`, 'warn');
          validate = false;
        }
      });
      return validate;
    },
    changeInvoiceType(nextIndex) {
      if (nextIndex < this.buttonPacienteFamiliars.length) {
        this.tipoButton = this.buttonPacienteFamiliars[nextIndex].id;
      } else {
        this.tipoButton = this.buttonPacienteFamiliars[0].id;
      }
      this.familiarSelect = null;
      this.contactosNewPacientes = [];
      this.newContacto = false;
    },
    busquedaAsincronaContactos(query) {
      this.temporalPageComboContacto = 1;
      const DELAY = 400;
      clearTimeout(this.delayTimeout);
      this.delayTimeout = setTimeout(() => this.getAllContactos(query), DELAY);
    },
    async getDataCreateContacto() {
      this.newContacto = true;
      if (this.newContacto) {
        if (this.contactosNewPacientes.length < 1) {
          await this.createContacto();
        }
        this.addNewContacto();
      }
    },
    addNewContacto() {
      const obj = {
        parentescoSelected: this.parentescos[0],
        nombres: null,
        paisSelected: this.paises[0],
        celular: null,
        codigoPais: this.paises[0].codigo_telefono,
        ci: null,
        mail: null,
      };
      this.contactosNewPacientes.push(obj);
    },
    setCodigoPais(index) {
      this.contactosNewPacientes[index].codigoPais = this.contactosNewPacientes[index].paisSelected.codigo_telefono;
    },
    validarCaracter(event) {
      const validKeys = new RegExp('^[0-9]*$');
      const controlKeys = ['Delete', 'Backspace', 'ArrowLeft', 'ArrowRight', 'KeyX', 'KeyC', 'KeyV', 'Home', 'End', 'Tab'];
      if (controlKeys.includes(event.code)) {
        switch (event.code) {
          case 'KeyX':
            if (!event.ctrlKey) {
              event.preventDefault();
            }
            break;
          case 'KeyC':
            if (!event.ctrlKey) {
              event.preventDefault();
            }
            break;
          case 'KeyV':
            if (!event.ctrlKey) {
              event.preventDefault();
            }
            break;
          default:
            break;
        }
        return;
      }
      if (!validKeys.test(event.key)) {
        event.preventDefault();
      }
    },
    reValidarCelular(index) {
      if (this.contactosNewPacientes[index].celular === '') {
        return;
      }
      const validKeys = new RegExp('^[0-9]*$');
      if (!validKeys.test(this.contactosNewPacientes[index].celular)) {
        this.contactosNewPacientes[index].celular = '';
      }
    },
    eliminarItemContacto(index) {
      this.contactosNewPacientes.splice(index, 1);
    },
  },
  computed: {
    ...mapState('modalHojaAdmision', [
      'familiar',
      'contactos',
      'isLoading',
      'parentescos',
      'paises',
    ]),
    tituloModal() {
      return !this.isEdit ? 'Crear Hoja de Admision' : 'Editar Hoja de Admision';
    },
    buttonClass() {
      return this.has_Admision || this.isAgregar ? 'btn-success' : 'btn-secondary';
    },
    buttonText() {
      return this.has_Admision ? 'Admisión' : 'Hoja de Admisión';
    },
    colClass() {
      return this.has_Admision ? 'col-sm-6' : 'col-sm-12';
    },
    isContacto() {
      return this.tipoButton === TIPO_BUTTON_ADMISION.CONTACTO;
    },
    isFamiliar() {
      return this.tipoButton === TIPO_BUTTON_ADMISION.FAMILIAR;
    },
    ...mapFields('modalHojaAdmision', [
      'temporalPageComboContacto',
      'newContacto',
      'familiarSelect',
      'contactosNewPacientes',
      'isAgregar',
    ]),
    showCargarMas() {
      // const isOkToSearch = this.temporalPageComboContacto * 20 < this.totalSearchPacientes;
      // return this.totalSearchPacientes > 20 && isOkToSearch;
      return true;
    },
  },
  watch: {
  },
};
</script>

<style >
@media print {
  @page {
    size: letter;
    margin: 1in;
  }
}
</style>
