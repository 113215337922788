import { getField, updateField } from 'vuex-map-fields';
import moment from 'moment';
// import axios from '@/modules/common/axiosERP';
// import { util } from '@/plugins/util';

export default {
  namespaced: true,
  state: {
    interConsultas: [],
    tabSolicitud: {
      solicitanteSelected: null,
      listaSolicitantes: [],
      fecha: new Date(),
      destinatarioSelected: null,
      listaDestinatarios: [],
      hora: moment().format('HH:mm:ss'),
      valoracion: null,
      resumeClinico: null,
      atencionesRealizadas: null,
      evolucionPaciente: null,
      motivo: null,
      respuesta: null,
      interconsulta_id: null,
      numero: 1,
      isEdit: false,
      index: null,
    },
    tabRespuesta: {
      solicitanteSelected: null,
      listaSolicitantes: [],
      fecha: new Date(),
      destinatarioSelected: null,
      listaDestinatarios: [],
      hora: moment().format('HH:mm:ss'),
      respuesta_id: null,
      informe: null,
      recomendacion: null,
    },
  },
  getters: {
    getField,
    getMedicoDestinatarios(state) {
      const SOLICITANTE_ID = state.tabSolicitud.solicitanteSelected
        ? state.tabSolicitud.solicitanteSelected.id : null;
      const MEDICO_DESTINARIOS = state.tabSolicitud.listaSolicitantes.filter((ele) => (
        ele.id !== SOLICITANTE_ID
      ));
      state.tabSolicitud.listaDestinatarios = MEDICO_DESTINARIOS;
      return MEDICO_DESTINARIOS;
    },
  },
  mutations: {
    updateField,
    setInterConsultas: (state, data) => { state.interConsultas = data; },
    setDataInicialSolicitud(state, data) {
      state.tabSolicitud.solicitanteSelected = data.solicitanteSelected;
      state.tabSolicitud.listaSolicitantes = data.listaSolicitantes;
      state.tabSolicitud.destinatarioSelected = data.destinatarioSelected;
      state.tabSolicitud.listaDestinatarios = data.listaDestinatarios;
      state.tabSolicitud.fecha = data.fecha;
      state.tabSolicitud.hora = data.hora;

      state.tabSolicitud.valoracion = data.valoracion;
      state.tabSolicitud.resumeClinico = data.resumeClinico;
      state.tabSolicitud.atencionesRealizadas = data.atencionesRealizadas;
      state.tabSolicitud.evolucionPaciente = data.evolucionPaciente;
      state.tabSolicitud.motivo = data.motivo;
      state.tabSolicitud.respuesta = data.respuesta;
      state.tabSolicitud.interconsulta_id = data.interconsulta_id;
      state.tabSolicitud.numero = data.numero;
      state.tabSolicitud.isEdit = data.isEdit;
      state.tabSolicitud.index = data.index;
    },
    setItemTabSolicitud(state, data) {
      state.tabSolicitud.solicitanteSelected = data.solicitanteSelected;
      state.tabSolicitud.listaSolicitantes = data.listaSolicitantes;
      state.tabSolicitud.destinatarioSelected = data.destinatarioSelected;
      state.tabSolicitud.listaDestinatarios = data.listaDestinatarios;
      state.tabSolicitud.fecha = new Date(moment(data.fecha));
      state.tabSolicitud.hora = data.hora;
      state.tabSolicitud.valoracion = data.valoracion;
      state.tabSolicitud.resumeClinico = data.resumeClinico;
      state.tabSolicitud.atencionesRealizadas = data.atencionesRealizadas;
      state.tabSolicitud.evolucionPaciente = data.evolucionPaciente;
      state.tabSolicitud.motivo = data.motivo;
      state.tabSolicitud.respuesta = data.respuesta;
      state.tabSolicitud.interconsulta_id = data.interconsulta_id;
      state.tabSolicitud.numero = data.numero;
      state.tabSolicitud.isEdit = data.isEdit;
      state.tabSolicitud.index = data.index;
    },
    setTabRespuesta(state, data) {
      state.tabRespuesta.solicitanteSelected = data.solicitanteSelected;
      state.tabRespuesta.listaSolicitantes = data.listaSolicitantes;
      state.tabRespuesta.fecha = data.fecha;
      state.tabRespuesta.destinatarioSelected = data.destinatarioSelected;
      state.tabRespuesta.listaDestinatarios = data.listaDestinatarios;
      state.tabRespuesta.hora = data.hora;
      state.tabRespuesta.respuesta_id = data.respuesta_id;
      state.tabRespuesta.recomendacion = data.recomendacion;
      state.tabRespuesta.informe = data.informe;
    },
  },
  actions: {
    resetTheModalInterConsulta({ commit }) {
      commit('setInterConsultas', []);
      const TAB_SOLICITUD = {
        solicitanteSelected: null,
        listaSolicitantes: [],
        destinatarioSelected: null,
        listaDestinatarios: [],
        fecha: null,
        hora: '',
        valoracion: null,
        resumeClinico: null,
        atencionesRealizadas: null,
        evolucionPaciente: null,
        motivo: null,
        respuesta: null,
        interconsulta_id: null,
        numero: 1,
        isEdit: false,
        index: null,
      };
      commit('setDataInicialSolicitud', TAB_SOLICITUD);
      const TAB_RESPUESTA = {
        solicitanteSelected: null,
        fecha: null,
        destinatarioSelected: null,
        hora: '',
        valoracion: null,
        resumeClinico: null,
        atencionesRealizadas: null,
        listaSolicitantes: [],
        listaDestinatarios: [],
        respuesta_id: null,
        informe: null,
        recomendacion: null,
      };
      commit('setTabRespuesta', TAB_RESPUESTA);
    },
  },
};
