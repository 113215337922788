<template>
  <b-modal
    id="modal-sincronizar-factura"
    :title="`Sincronizar: Facturador ID: ${empresaEmisorId}`"
    size="lg"
    @show="obtenerIntegracionFactura"
    @hidden="clearFields"
    hide-footer
  >
    <div v-if="isInterfaceReady" class="modal-body">
      <div class="form-row">
        <div class="col-12 mb-4" style="padding:8px;">
          <div class="col-sm-12" style="background: #f4b375;  padding:5px; border-top: groove; border-radius: 5px;">
            <div class="form-group row">
              <div class="col-sm-3 text-right">
                <label>Integrar</label>
              </div>
              <div class="col-sm-4">
                <input type="text" class="form-control" v-model="tokenIntegracion">
              </div>
              <div class="col-sm-2">
                <button class="btn btn-secondary btn-block" @click="integrarFacturaElectronica">Validar</button>
              </div>
              <div class="col-sm-1 pl-0">
                <button class="btn btn-secondary btn-block px-0" @click="tokenIntegracion = null;">
                  <i class="i-Back1"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="form-group row">
            <label class="col-sm-3 col-form-label text-right">
              Ambiente:
            </label>
            <div class="col-sm-5">
              <input type="text" disabled class="form-control" v-model="ambiente">
            </div>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="form-group row">
            <label class="col-sm-3 col-form-label text-right">
              Paquete:
            </label>
            <div class="col-sm-5">
              <input type="text" disabled class="form-control" v-model="paqueteFacturacion">
            </div>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="form-group row">
            <label class="col-sm-3 col-form-label text-right">
              Facturacion:
            </label>
            <div class="col-sm-5">
              <input
                v-model="facturacion"
                type="text"
                disabled
                class="form-control"
              >
            </div>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="form-group row">
            <label class="col-sm-3 col-form-label text-right">
              Documento por Sector:
            </label>
            <div class="col-sm-7">
              <multiselect2
                v-model="documentoPorSector"
                disabled
                track-by="id"
                label="emisor_descripcion"
                placeholder="Seleccione"
                multiple
                :options="[]"
              />
            </div>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="form-group row">
            <label class="col-sm-3 col-form-label text-right">
              Proveedor de Factura:
            </label>
            <div class="col-sm-4">
              <input type="text" disabled class="form-control" placeholder="Gemgloo" v-model="proveedorFactura">
            </div>
            <div class="col-sm-3">
              <input type="text" disabled class="form-control" placeholder="Nit" v-model="proveedorFacturaNit">
            </div>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="row">
            <div class="col-sm-6"></div>
            <div class="col-sm-6" style="left: 8%;">
                Feha de Vencimiento:
            </div>
          </div>
          <hr style="margin-top:auto;">
          <div class="row">
            <div class="col-sm-6">
            </div>
            <div class="col-sm-6">
                <div class="form-group row">
                    <label class="col-sm-6 col-form-label text-right">Token Facturador:
                    </label>
                    <label class="col-sm-6 col-form-label">{{ fechaFinIntegracion }}
                    </label>
                    <label class="col-sm-6 col-form-label text-right">Token Siat:
                    </label>
                    <label class="col-sm-6 col-form-label">{{ fechaVencimientoSiat }}
                    </label>
                    <label v-show="firmaDigital" class="col-sm-6 col-form-label text-right">Firma Digital:
                    </label>
                    <label v-show="firmaDigital" class="col-sm-6 col-form-label">{{ firmaDigital }}
                    </label>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="text-center text-danger">
        <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
        <strong>Cargando...</strong>
      </div>
    </div>
  </b-modal>
</template>

<script>
import axios from '@/modules/common/axiosERP';
import Multiselect2 from '@/components/util/vue-multiselect2';
import { util } from '@/plugins/util';
import moment from 'moment';

moment.locale('es');
export default {
  name: 'ModalSyncFactura',
  components: {
    Multiselect2,
  },
  props: {
    /**
     * medico seleccionado
     */
    medico: {
      type: Object,
      default: null,
    },
  },
  created() {},
  data() {
    return {
      isInterfaceReady: false,
      empresaEmisorId: '',
      tokenIntegracion: null,
      ambiente: null,
      paqueteFacturacion: null,
      facturacion: null,
      documentoPorSector: [],
      proveedorFactura: null,
      proveedorFacturaNit: null,
      listaCiudades: [],
      firmaDigital: null,
      fechaFinIntegracion: null,
      fechaVencimientoSiat: null,
    };
  },
  methods: {
    async obtenerIntegracionFactura() {
      await this.$nextTick();
      if (this.medico === null) {
        util.showNotify('Ocurrio un error al abrir el Modal para la integración.', 'error');
        return;
      }
      try {
        this.isInterfaceReady = false;
        const response = await axios.get(`/clinic/tokenIntegracion/${this.medico.id}`);
        const DATA = response.data.data;
        if (DATA.conexion) {
          this.ambiente = DATA.conexion.emisor_ambiente_nombre;
          this.paqueteFacturacion = DATA.conexion.emisor_paquete_nombre;
          this.facturacion = DATA.conexion.emisor_modalidad_nombre;
          this.proveedorFactura = DATA.conexion.emisor_proveedor_software;
          this.proveedorFacturaNit = DATA.conexion.emisor_nit;
          this.empresaEmisorId = DATA.conexion.emisor_empresa_numero;
          if (DATA.conexion.fecha_fin_firma) {
            this.firmaDigital = moment(DATA.conexion.fecha_fin_firma).format('dddd- DD/MM/YYYY');
          }
          this.fechaFinIntegracion = moment(DATA.conexion.emisor_token_expired_at).format('dddd- DD/MM/YYYY');
          this.fechaVencimientoSiat = moment(DATA.conexion.fecha_vencimiento_token_siat).format('dddd- DD/MM/YYYY');
        }
        this.documentoPorSector = DATA.doc_sector;
        this.listaCiudades = DATA.ciudades;
        this.isInterfaceReady = true;
      } catch (error) {
        console.log(error);
      }
    },
    clearFields() {
      this.tokenIntegracion = null;
      this.empresaEmisorId = '';
      this.ambiente = null;
      this.paqueteFacturacion = null;
      this.facturacion = null;
      this.documentoPorSector = [];
      this.proveedorFactura = null;
      this.proveedorFacturaNit = null;
      this.listaCiudades = [];
    },
    integrarFacturaElectronica() {
      if (!this.tokenIntegracion) {
        util.showNotify('El token en un campo requerido', 'error');
        return;
      }
      if (this.medico.ciudad_id === 0 || this.medico.ciudad_id === null) {
        util.showNotify('El medico no tiene configurado la ciudad en sus datos', 'error');
        return;
      }
      const ciudadMedico = this.listaCiudades.find((ciudad) => ciudad.id === this.medico.ciudad_id);
      const request = {
        token: this.tokenIntegracion,
        nit: this.medico.nit,
        ciudad: ciudadMedico.nombre,
        medico_id: this.medico.id,
      };
      axios.post('/clinic/tokenIntegrationStore', request)
        .then((response) => {
          this.obtenerIntegracionFactura();
          util.showNotify(response.data.message, 'success');
          this.tokenIntegracion = null;
          this.$emit('updateListaMedicos');
        })
        .catch((error) => {
          const response = error.response.data;
          if (error.response) {
            if (response.error === 422) {
              util.showNotify(error.response.data.message, 'error');
            }
          } else {
            const message = 'Error, hubo problemas con el servidor, contactese con el Administrador.';
            util.showNotify(message, 'error');
          }
        });
    },
  },
};
</script>
